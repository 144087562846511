import { HeaderNavModel } from '../../../model/HeaderNav'
import { useLocalizationStore } from '../../../../localization/store/localization.store'
import { appRoutePath } from '../../../../router/util/app-route-path'
import { observer } from 'mobx-react-lite'
import { LINK_LABEL_CLASS_NAME, NavListItem } from '../../../../../component/common/original/molecule/NavListItem'
import { TranslatableText } from '../../../../localization/component/atom/TranslatableText'
import { mergeTranslationWithObjects } from '../../../../localization/util/localization.helper'
import { useUserStore } from 'src/feature/user/store/user.store'
import { DefaultSiteMap } from 'src/feature/user/model/SiteMap'
import { LocalizedString } from 'typesafe-i18n'
import { Icon } from '@mui/material'
import { iconMapping } from 'src/util/icon-mapping'

export type MainLinksProps = {
  isOpen: boolean
}

export const MainLinks = observer(({ isOpen }: MainLinksProps) => {
  const { translation } = useLocalizationStore()
  const { sitemap } = useUserStore()
  const navLinks = {
    company: {
      label: sitemap?.modules.find(m => m.key === 'company')?.name || translation.components.header.companyDefaultLink() as LocalizedString,
      props: {
        link: appRoutePath.companyPage,
        hidden: true,
        icon: 'BusinessIcon',
      },
    },
    myDashboard: {
      props: {
        link: appRoutePath.profileInfo,
        icon: 'DashboardCustomizeRoundedIcon',
      },
    },
    myGrowth: {
      props: {
        link: appRoutePath.userDashboard,
        icon: 'TrendingUpIcon',
      },
    },
    myTeam: {
      props: {
        link: appRoutePath.administratorDashboard,
        icon: 'LeaderboardIcon',
      },
    },
    eLearning: {
      props: {
        link: appRoutePath.eLearning,
        icon: 'SchoolIcon',
      },
    },
    eLearningV2: {
      props: {
        link: appRoutePath.learning,
        icon: 'SchoolIcon',
      },
    },
    forum: {
      props: {
        link: appRoutePath.forum,
      },
    },
  }

  const nav = mergeTranslationWithObjects(translation.components.header.nav, navLinks) as unknown as HeaderNavModel
  const effectiveSitemap = {
    modules: [
      ...(DefaultSiteMap.modules || []), // Include DefaultSiteMap modules
      ...(sitemap?.modules || []), // Include sitemap modules
    ],
  }
  const list = Object.entries(nav).filter(n => effectiveSitemap.modules.some(m => m.key === n[0]))

  return (
    <>
      {list.map(([id, { label, props }]) => {
        return (
          <NavListItem
            className="third-step"
            key={id}
            textValue={label}
            isMain={true}
            {...props}
            sx={{ display: 'flex', alignItems: 'center', gap: 7 }}
          >
            <Icon
              sx={{
                color: 'text.primary',
                transition: 'color 0.3s ease',
                height: 22,
                width: 22,
              }}
              className={LINK_LABEL_CLASS_NAME}
              component={iconMapping[props.icon ? props.icon : 'CameraRoundedIcon']}
            />
            {isOpen && (
              <TranslatableText
                component={'p'}
                className={LINK_LABEL_CLASS_NAME}
                textValue={label}
                sx={{ transition: 'color 0.3s ease' }}
              />
            )}
          </NavListItem>
        )
      })}
    </>
  )
})
