import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useELearningStore } from '../../store/e-learning.store'
import { ELearningCourseCategory } from '../../model/ELearningCourseCategory'
import { CategoryItem } from './CategoryItem'
import { Box, Fade, Pagination, Typography, useMediaQuery } from '@mui/material'
import { AppCarousel } from 'src/component/common/analog/molecule/carousel/AppCarousel'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { Caption } from 'src/feature/localization/component/atom/Caption'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { CourseListPresentationItem } from './CourseListPresentationItem'
import { AppProgressLine } from 'src/component/common/analog/molecule/loader/AppProgressLine'
import { CategorySearch } from './CategorySearch'

export const CategoryList = observer(() => {
  const eLearningStore = useELearningStore()
  const { translation } = useLocalizationStore()
  const { isLoading: isLoadingAllCourses } = eLearningStore.getGenericFieldsState('getAllCourses')
  const { isLoading: isLoadingSearch } = eLearningStore.getGenericFieldsState('search')
  const isSmallScreen = useMediaQuery('(max-width:900px)')

  const [searchTerm, setSearchTerm] = useState('')
  const isLoading = isLoadingAllCourses || isLoadingSearch
  const texts = translation.pages.eLearning
  const [currentPage, setCurrentPage] = useState(1)
  const pageSize = 30
  
  useEffect(() => {
    eLearningStore.clearSearch()
    eLearningStore.selectedFilters = undefined
    if (eLearningStore.categories === undefined) {
      eLearningStore.loadCategoriesIfNeeded()
    }
    eLearningStore.getAllCourses('all', 0, pageSize)
  }, [])

  const transform = (category: ELearningCourseCategory, index: number) => {
    return <CategoryItem key={category.id} item={category} index={index} />
  }

  const extendResults = async (newPageLength: number) => {
    if (searchTerm.length > 0) {
      await eLearningStore.search(searchTerm, newPageLength, pageSize)
    } else {
      await eLearningStore.getAllCourses('all', newPageLength, pageSize)
    }
  }

  return (
    <AppBox sx={styles.root}>
      <CategorySearch setSearchTerm={(term) => {
        setCurrentPage(1)
        setSearchTerm(term)
      }} />
      {!isSmallScreen && <Box sx={styles.chipContainer}>
        {eLearningStore.categories && eLearningStore.categories.map(transform)}
      </Box>}
      {isSmallScreen && <AppCarousel showProgressBar={false}>
        {eLearningStore.categories && eLearningStore.categories.map(transform)}
      </AppCarousel>
      }
      {<AppProgressLine isShowing={isLoading} />}
      {!isLoading && eLearningStore.courseList && eLearningStore.courseList.length > 0 && eLearningStore.courseList.length !== eLearningStore.courseCount && (
        <AppBox alignItems='center' width='100%' gap={5}>
          <Typography variant='h4'>{`${eLearningStore.courseCount} ${texts.courses().toLowerCase()}`}</Typography>
          <Pagination
            shape='rounded'
            color='primary'
            count={Math.ceil((eLearningStore.courseCount || 0) / pageSize)}
            page={currentPage}
            showFirstButton
            showLastButton
            onChange={(_, page) => {
              const newPageLength = (page - 1) * pageSize
              setCurrentPage(page)
              extendResults(newPageLength)
            }}
            sx={{ '& .MuiPaginationItem-root': { fontSize: 13, height: 23 } }}
          />
        </AppBox>
      )}
      {!isLoading && eLearningStore.courseList && eLearningStore.courseList.length === 0 && <Caption sx={{ color: 'text.secondary' }} textValue={texts.missingCourse} />}
      {!isLoading && eLearningStore.courseList && eLearningStore.courseList.length > 0 && (
        <Fade in={eLearningStore.courseList?.length > 0} mountOnEnter unmountOnExit>
          <AppBox sx={styles.courseList}>
            {eLearningStore.courseList.map(course => (
              <CourseListPresentationItem key={course.id} item={course} />
            ))}
          </AppBox>
        </Fade>
      )}
    </AppBox>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
  },
  chipContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
    flexWrap: 'wrap',
  },
  courseList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: { xs: 'center', lg: 'flex-start' },
    gap: 15,
  },
}
