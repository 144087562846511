import { AppBox } from '../../../../component/common/analog/molecule/AppBox'
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useELearningStore } from '../../store/e-learning.store'
import { AppProgressLine } from 'src/component/common/analog/molecule/loader/AppProgressLine'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { AppPaper } from 'src/component/common/analog/molecule/AppPaper'
import { Caption } from 'src/feature/localization/component/atom/Caption'
import { Image } from 'src/feature/image/component/Image'
import { AppButton } from 'src/component/common/analog/molecule/button/AppButton'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import FastForwardIcon from '@mui/icons-material/FastForward'
import { AppIconButton } from 'src/component/common/analog/molecule/button/AppIconButton'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import FavoriteIcon from '@mui/icons-material/Favorite'
import GoodHabitz from 'src/feature/image/asset/goodhabitz.svg'
import Diploma from 'src/feature/image/asset/diploma.svg'
import Udemy from 'src/feature/image/asset/udemy.svg'
import { API_BASE_URL, DIPLOMA_ID, GOODHABITS_ID, UDEMY_ID } from 'src/util/constant'
import { CourseProgressRow } from './CourseProgressRow'
import { useUserStore } from 'src/feature/user/store/user.store'
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo'
import { OrgSettingsModal } from './OrgSettingsModal'
import { useAuthStore } from 'src/feature/auth/store/auth.store'
import { OrganisationRole } from 'src/feature/control-panel/model/OrganisationRole'
import { Palette } from 'src/feature/theme/model/Palette'
import { useB2bStore } from 'src/feature/b2b/store/b2b.store'
import { Typography } from '@mui/material'

export type CoursePresentationProps = {
  courseId: string
}

export const CoursePresentation = observer(({ courseId }: CoursePresentationProps) => {
  const eLearningStore = useELearningStore()
  const authStore = useAuthStore()
  const userStore = useUserStore()
  const b2bStore = useB2bStore()
  const { translation } = useLocalizationStore()
  const texts = translation.pages.eLearning
  const { isLoading } = eLearningStore.getGenericFieldsState('getCourseInfo')
  const [showOrgSettings, setShowOrgSettings] = useState(false)

  const toggleFavorite = async (e: React.FormEvent<HTMLFormElement>, id: string) => {
    e.stopPropagation()
    await eLearningStore.toggleFavoriteCourse(id)
    await eLearningStore.updatePersonalCoursePlan()
    await eLearningStore.getRecommendedCourses()
  }
  
  useEffect(() => {
    eLearningStore.getCourseInfo(courseId)
  }, [courseId])

  const onDeleteCoursePlanItem = async () => {
    b2bStore.loadCourseOrganisationPlanItem(courseId)
  }

  if (isLoading || !eLearningStore.course) {
    return <AppBox><AppProgressLine isShowing={true} /></AppBox>
  }

  const course = eLearningStore.course
  const startUrl = course.provider.id === GOODHABITS_ID ? 'https://my.goodhabitz.com/sso/yohrs'
    : course.provider.id === UDEMY_ID ? `https://${course.redirectUrl}/organization/init-sso-login/?redirect_url=https://${course.redirectUrl}/course/${course.providerCourseId}/enroll/`
      : `${API_BASE_URL}/e-learning/startdiploma/${course.id}?token=${authStore.accessToken}`

  return (
    <AppBox sx={styles.root}>
      <AppBox sx={styles.container}>
        <Typography sx={{ fontSize: 18 }}>{course.title}</Typography>
        <Image src={course.imageUrl} alt={course.title} outerProps={{ sx: styles.imageRoot }} innerProps={{ sx: styles.image }} />
        {!course.deleted && <AppBox sx={styles.buttonGroup}>
          {course.progress && <CourseProgressRow progress={course.progress} />}
          <AppBox sx={{ gap: 10 }}>
            <AppIconButton onClick={(e: React.FormEvent<HTMLFormElement>) => toggleFavorite(e, course.id)} sx={styles.favIconBtn}>{course.isFavorite ? <FavoriteIcon sx={{ color: Palette.DANGER }} fontSize='small' /> : <FavoriteBorderIcon fontSize='small' />}</AppIconButton>
            {authStore.hasRole(OrganisationRole.LearningAdministrator) && <AppIconButton onClick={() => setShowOrgSettings(true)} sx={styles.iconButton}><ContentPasteGoIcon fontSize='small' /></AppIconButton>}
          </AppBox>
        </AppBox>}
        {/* {!userStore.hasSubscriptionPlan(course.provider.products) && !userStore.hasActiveTrial() && <AppButton variant='contained' fontSize={17} size='small' color='primary' onClick={() => navigate(appRoutePath.activateLearning)} textValue={texts.activateLearningButton} />} */}
        {/* {!userStore.hasSubscriptionPlan(course.provider.products) && userStore.hasActiveTrial() && !userStore.isDemoUser() && <Tooltip title={texts.daysLeftInTrial(userStore.daysLeftInTrial())}><a href={startUrl} target='blank' style={{ textDecoration: 'none' }}><AppButton onClick={() => eLearningStore.targetCourseUrl = course.url} textValue={course.progress ? texts.continueButton() : texts.startButton()} variant='contained' fontSize={17} size='small' color='primary' endIcon={course.progress ? <FastForwardIcon /> : <PlayArrowIcon />} /></a></Tooltip>} */}
        {userStore.hasSubscriptionPlan(course.provider.products) && <a href={startUrl} target='blank' style={{ textDecoration: 'none', alignSelf: 'center' }}><AppButton onClick={() => eLearningStore.targetCourseUrl = course.url} textValue={course.progress ? texts.continueButton() : texts.startButton()} variant='contained' fontSize={12} size='small' color='primary' endIcon={course.progress ? <FastForwardIcon fontSize='small' /> : <PlayArrowIcon fontSize='small' />} /></a>}
        {course.provider.id === GOODHABITS_ID && <AppBox sx={styles.partner}>
          <Caption color="text.secondary" textValue={texts.partnerText} />
          <Image src={GoodHabitz} alt='GoodHabitz' />
        </AppBox>}
        {course.provider.id === DIPLOMA_ID && <AppBox sx={styles.partner}>
          <Caption color="text.secondary" textValue={texts.partnerText} />
          <Image src={Diploma} alt='Diploma' />
        </AppBox>}
        {course.provider.id === UDEMY_ID && <AppBox sx={styles.partner}>
          <Caption color="text.secondary" textValue={texts.partnerText} />
          <Image src={Udemy} alt='UDEMY' />
        </AppBox>}
      </AppBox>
      <AppPaper p={24} sx={styles.descWrapper}>
        {course.deleted && <Caption color="text.secondary" textValue={texts.deletedCourse} />}
        <div dangerouslySetInnerHTML={{ __html: course.description }} />
      </AppPaper>
      {authStore.hasRole(OrganisationRole.LearningAdministrator) && showOrgSettings && <OrgSettingsModal show={showOrgSettings} courseId={course.id} onClose={() => setShowOrgSettings(false)} onDeleteCoursePlanItem={onDeleteCoursePlanItem} />}
    </AppBox>
  )
})

const styles = {
  root: {
    flexDirection: {
      md: 'row',
      xs: 'column',
    },
    gap: 20,
    padding: 20,
  },
  container: {
    width: { xs: '100%', md: '30%' },
    flexDirection: 'column',
    gap: 20,
  },
  buttonGroup: {
    flexDirection: { xs: 'column-reverse', md: 'row-reverse', lg: 'row-reverse' },
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 20,
  },
  iconButton: {
    borderWidth: 0,
    padding: 0,
    color: 'secondary.main',
    backgroundColor: 'unset !important',
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'translateX(3px) scale(1.1)',
    },
  },
  imageRoot: {
    overflow: 'hidden',
    alignSelf: 'center',
  },
  image: {
    width: '100%',
    objectFit: 'scale-down',
  },
  partner: {
    flexDirection: 'column',
    gap: 10,
    margin: 10,
    width: 'max(150px)',
  },
  descWrapper: {
    width: { xs: '100%', md: '70%' },
    borderRadius: '10px',
    maxHeight: '550px',
    overflow: 'scroll',
  },
  favIconBtn: {
    borderWidth: 0,
    padding: 0,
    color: 'secondary.main',
    backgroundColor: 'unset !important',
    transition: 'color 0.3s ease',
    '&:hover': {
      color: Palette.DANGER,
    },
  },
}
