import { appRoutePath } from '../../router/util/app-route-path'
import { useLocalizationStore } from '../../localization/store/localization.store'
import { observer } from 'mobx-react-lite'
import { NavModel } from '../../../model/Nav'
import { Sidebar } from '../../sidebar/component/Sidebar'
import { mergeTranslationWithObjects } from '../../localization/util/localization.helper'
import { useUserStore } from 'src/feature/user/store/user.store'
import { useEffect, useState } from 'react'
import { AppCircleLoader } from 'src/component/common/analog/molecule/loader/AppCircleLoader'
import { useAuthStore } from 'src/feature/auth/store/auth.store'
import { OrganisationRole } from '../model/OrganisationRole'
import { Collapse, Icon } from '@mui/material'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { TranslatableText } from 'src/feature/localization/component/atom/TranslatableText'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { If } from 'src/component/common/original/atom/If'
import { AppUnstyledButton } from 'src/component/common/analog/molecule/button/AppUnstyledButton'
import { iconMapping } from 'src/util/icon-mapping'
import { MainLinksProps } from 'src/feature/header/component/original/organism/MainLinks'

export type ControlPanelSidebarProps = {
  isOpen: boolean
}

export const ControlPanelSidebar = observer(({ isOpen }: MainLinksProps) => {
  const { translation } = useLocalizationStore()
  const userStore = useUserStore()
  const authStore = useAuthStore()
  const { isLoading } = userStore.getGenericFieldsState('handleGetOrganisation')

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { controlPanelSidebar } = translation.components
  const navLinks: NavModel<'props.link'> = {
    controlpanelUsers: {
      props: {
        link: appRoutePath.controlpanelUsers,
        hidden: !authStore.hasRole(OrganisationRole.SystemAdministrator),
        icon: 'PeopleAltRoundedIcon',
      },
    },
    controlpanelOrganisation: {
      props: {
        link: appRoutePath.controlpanelOrganisation,
        hidden: !authStore.hasRole(OrganisationRole.SystemAdministrator),
        icon: 'BusinessIcon',
      },
    },
    controlpanelModules: {
      props: {
        link: appRoutePath.controlpanelModules,
        hidden: !authStore.hasRole(OrganisationRole.SystemAdministrator),
        icon: 'ExtensionRoundedIcon',
      },
    },
    controlpanelSystem: {
      props: {
        link: appRoutePath.controlpanelSystem,
        hidden: !authStore.hasRole(OrganisationRole.SystemAdministrator),
        icon: 'TuneRoundedIcon',
      },
    },
  }

  const desktopNav = mergeTranslationWithObjects(controlPanelSidebar.nav, navLinks) as unknown as NavModel

  useEffect(() => {
    userStore.handleGetOrganisation()
  }, [])

  if (isLoading) {
    return <AppCircleLoader isShowing={true} />
  }

  return (
    <>
      <AppBox
        justifyContent='space-between'
        alignItems='center'
        width='100%'
        sx={{ cursor: 'pointer' }}
        onClick={() => isOpen && setIsMenuOpen(!isMenuOpen)}
      >
        <AppBox justifyContent='center' alignItems='center' gap={7} >
          <Icon
            sx={{
              color: 'text.primary',
              transition: 'color 0.3s ease',
              height: 22,
              width: 22,
            }}
            component={iconMapping['SettingsIcon']}
          />
          {isOpen && <TranslatableText component={'p'} textValue={controlPanelSidebar.title()} />}
        </AppBox>
        <AppUnstyledButton size='small'>
          <If condition={isMenuOpen && isOpen}>
            <KeyboardArrowUpIcon fontSize='small' sx={{ color: 'text.primary' }} />
          </If>
          <If condition={!isMenuOpen && isOpen}>
            <KeyboardArrowDownIcon fontSize='small' sx={{ color: 'text.primary' }} />
          </If>
        </AppUnstyledButton>
      </AppBox>
      <Collapse
        in={isMenuOpen && isOpen}
        sx={{
          width: '100%',
          marginLeft: 40,
        }}
      >
        <Sidebar nav={desktopNav} />
      </Collapse>
    </>
  )
})