import { Box } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Title1 } from 'src/feature/localization/component/atom/Title1'
import { TeamInsightCards } from '../organisms/TeamInsightCards'

export const TeamInsights = observer(() => {
  return (
    <Box sx={styles.root}>
      <Title1>Team insights</Title1>
      <TeamInsightCards />
    </Box>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    overflow: 'hidden',
    gap: 20,
  },
}
