import React, { useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import Box from '@mui/material/Box'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { Collapse, Menu, Typography } from '@mui/material'
import { useBreakpoints } from 'src/feature/theme/hook/useBreakpoints'
import { NavListItem } from '../../../../../component/common/original/molecule/NavListItem'
import { SxHelperStyle } from '../../../../theme/style/sx-helper.style'
import Badge from '@mui/material/Badge'
import { Caption } from 'src/feature/localization/component/atom/Caption'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { AppDivider } from 'src/component/common/analog/molecule/AppDivider'

const notifications = [
  { id: 1, message: '-- In Progress --' },
]

export const NotificationLink = observer(() => {
  const [showMenu, setShowMenu] = useState(false)
  const menuItemRef = useRef(null)
  const { isMd } = useBreakpoints()

  const handleOpen = () => {
    setShowMenu(!showMenu)
  }

  const handleClose = () => {
    setShowMenu(false)
  }

  return (
    <React.Fragment>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} ref={menuItemRef}>
        <NavListItem className="first-step" onClick={handleOpen} isMain={true} sx={styles.root}>
          <Badge badgeContent={notifications.length} color="primary">
            <NotificationsIcon fontSize='small' />
          </Badge>
        </NavListItem>
        {isMd ? (
          <Collapse in={showMenu}>
            <Box sx={styles.notificationList}>
              <Typography fontSize={14}>Notifications</Typography>
              {/* {notifications.map(notification => (
                <Box key={notification.id} sx={styles.notificationItem}>
                  {notification.message}
                </Box>
              ))} */}
            </Box>
          </Collapse>
        ) : (
          <Menu
            anchorEl={menuItemRef.current}
            id="notification-menu"
            open={showMenu}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                width: 350,
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 17,
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  backgroundColor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <Typography variant='h4'>Notifications</Typography>
            <AppDivider sx={{ margin: '5px 0' }} />
            <AppBox alignItems='center' justifycontents='center' gap={5}>
              <Caption sx={{ color: 'text.secondary' }}>2025-03-17: </Caption>
              <Typography>It's time to finish your lessons for today</Typography>
            </AppBox>
            {/* {notifications.map(notification => (
              <MenuItem key={notification.id}>{notification.message}</MenuItem>
            ))} */}
          </Menu>
        )}
      </Box>
    </React.Fragment>
  )
})

const styles = {
  root: {
    ...SxHelperStyle.flexCenter,
    flexDirection: 'row',
    color: 'text.primary',
  },
  notificationList: {
    width: 350,
    backgroundColor: 'background.paper',
    boxShadow: '0px 2px 8px rgba(0,0,0,0.32)',
    borderRadius: '4px',
    padding: '10px',
  },
  notificationItem: {
    padding: '10px 0',
    borderBottom: '1px solid #e0e0e0',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
}
