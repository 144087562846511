import Typography, { TypographyProps } from '@mui/material/Typography'
import { TranslatableBox, TranslatableBoxProps } from './TranslatableBox'
import { BeforeConvertingToRemPxUnit } from '../../../theme/model/CssUnit'

export type TranslatableTextProps = Omit<TranslatableBoxProps, 'variant'> & TypographyProps

export type FontVolume = 'xs' | 'sm'

export type TranslatableTextVariantProps = Omit<TranslatableTextProps, 'component'> & {
  volume?: FontVolume
}

export function TranslatableText(props: TranslatableTextProps) {
  const { variant, component, volume, ...rest } = props

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <TranslatableBox
      fz={handleVolume(volume)}
      component={typographyProps => <Typography {...typographyProps} variant={variant} component={component} />}
      {...rest}
    />
  )
}

function handleVolume(volume: FontVolume): BeforeConvertingToRemPxUnit | undefined {
  switch (volume) {
  case 'xs':
    return 12
  case 'sm':
    return 14
  }
}
