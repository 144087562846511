import { createStore } from '../../../util/mobx/generic-store/store-creator'
import { GenericStore } from '../../../util/mobx/generic-store/generic.store'
import { INote } from '../model/notification'

export class NotificationStore extends GenericStore {
  public notifications: INote[] = []

  constructor() {
    super('NotificationStore')

    super.observe(this)
    super.persist({ encrypt: false })
  }

  enqueueSnackbar(note: INote) {
    const key = new Date().getTime() + Math.random()
    this.notifications.push({
      key,
      ...note,
      options: {
        key,
        ...note.options,
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      },
    })
  }

  reset() {
    this.notifications = []
  }

  removeSnackbar(note: INote) {
    const index = this.notifications.findIndex(el => el.key === note.key)
    this.notifications.splice(index, 1)
  }

  enqueueError(message: string, options?: object) {
    this.enqueueSnackbar({ message, options: { variant: 'error', ...options } })
  }

  enqueueInfo(message: string, options?: object) {
    this.enqueueSnackbar({ message, options: { variant: 'info', ...options } })
  }

  enqueueSuccess(message: string, options?: object) {
    this.enqueueSnackbar({ message, options: { variant: 'success', ...options } })
  }
}

export const {
  storeInstance: notificationStoreInstance,
  useStore: useNotificationStore,
  StoreProvider: NotificationStoreProvider,
} = createStore(new NotificationStore())
