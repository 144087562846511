import { HeaderProps } from '../../../model/HeaderProps'
import { AppStack, AppStackProps } from '../../../../../component/common/analog/molecule/list/AppStack'
import { AppNav } from '../../../../../component/common/analog/molecule/basic/AppNav'
import { ChildrenProps } from '../../../../../model/React'
import { handlePassedSx } from '../../../../theme/model/Sx'

export type DesktopHeaderProps = HeaderProps & ChildrenProps & {
  listProps?: AppStackProps
}

export function DesktopHeader(props: DesktopHeaderProps) {
  const {
    listProps: {
      sx: listSx = [],
      ...restListProps
    } = {},
  } = props

  return (
    <AppNav>
      <AppStack
        sx={[
          styles.list,
          ...handlePassedSx(listSx),
        ]}
        spacing={24}
        alignItems="center"
        {...restListProps}
      >
        {props.children}
      </AppStack>
    </AppNav>
  )
}

const styles = {
  list: {
    li: {
      fontSize: 17,
    },
  },
}
