import lodashUnion from 'lodash/union'

export const authRouteIds = [
  'signIn',
  'forgotPassword',
  'resetPassword',
  'verifyAccount',
] as const

export const onBoardingRouteIds = [
  'signUp',
] as const

export const profileRouteIds = [
  'profileInfo',
  'editProfile',
  'editPositions',
  'subscription',
  'paymentHistory',
  'verifyAccount',
  'ssoVerify',
  'activateLearning',
] as const

export const blogRouteIds = ['blog', 'blogArticle'] as const

export const platformRouteIds = ['eLearning', 'platformArticle', 'learning', 'learningCategory', 'course'] as const

export const knowledgeRouteIds = [
  'faq',
  'faqGroup',
  'additionalServices',
] as const

export const maintenanceRouteIds = ['maintenance'] as const

export const forumRouteIds = ['forum'] as const

export const payrollRouteIds = ['payroll'] as const

export const villkorRouteIds = ['villkor'] as const

export const b2bRouteIds = ['login'] as const

export const controlPanelds = ['controlpanelUsers', 'controlpanelOrganisation', 'controlpanelModules', 'controlpanelSystem'] as const

export const companyPageIds = ['companyPage', 'companyPageEditItem'] as const

export const skillAssessment = ['skillAssessment'] as const

export const dashboardIds = [
  'userDashboard',
  'administratorDashboard',
  'administratorUsers',
  'administratorUserDetails',
] as const

export const routeIds = lodashUnion(
  authRouteIds,
  onBoardingRouteIds,
  profileRouteIds,
  blogRouteIds,
  knowledgeRouteIds,
  maintenanceRouteIds,
  forumRouteIds,
  payrollRouteIds,
  platformRouteIds,
  villkorRouteIds,
  b2bRouteIds,
  controlPanelds,
  companyPageIds,
  skillAssessment,
  dashboardIds,
)

export type RouteId = typeof routeIds[number];

export type DynamicRouteId = Extract<RouteId, 'payment' | 'blogArticle' | 'faqGroup' | 'platformArticle' | 'learningCategory' | 'administratorUsers' | 'administratorUserDetails' | 'login'>

export type StaticRouteId = Exclude<RouteId, DynamicRouteId>

export type RouteIdList = readonly RouteId[];
