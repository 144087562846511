import { Box, Collapse, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { AppAnimatedText } from 'src/component/common/analog/molecule/AppAnimatedText'
import { AppProgressLine } from 'src/component/common/analog/molecule/loader/AppProgressLine'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { useSkillAssessmentStore } from 'src/feature/skill-assessment/store/skill-assessment.store'
import { useUserStore } from 'src/feature/user/store/user.store'

export const GetStarted = observer(() => {
  const { translation } = useLocalizationStore()
  const translations = translation.pages.myGrowth
  const { userData, sitemap } = useUserStore()
  const skillAssessmentStore = useSkillAssessmentStore()
  const { isLoading } = skillAssessmentStore.getGenericFieldsState('getSkillSets')
  const [open, setOpen] = useState(false)

  useEffect(() => {
    skillAssessmentStore.getSkillSets()
  }, [])

  useEffect(() => {
    if (!isLoading && (sitemap?.modules ?? []).filter(s => s.key === 'skillGap').length > 0 && !((skillAssessmentStore.skillSets?.skills ?? []).length > 0)) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [isLoading, skillAssessmentStore.skillSets])

  return (
    <Box sx={styles.root}>
      <AppAnimatedText
        text={`${translations.welcomeMsg()}, ${userData?.firstName || 'User'}!`}
        delay={50}
        sx={{ fontSize: 30 }}
      />
      <Typography
        sx={{ color: 'text.secondary' }}
      >{translations.welcomeDesc()}</Typography>
      <Collapse in={open} timeout={400} mountOnEnter unmountOnExit>
        <Typography sx={{ fontWeight: 600, textAlign: 'center', color: 'secondary.main' }}>{translations.noRolesMsg()}</Typography>
      </Collapse>
      <AppProgressLine sx={{ width: '5rem' }} isShowing={isLoading} />
    </Box>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: 5,
    padding: '0 1rem',
  },
}