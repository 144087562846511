import Divider, { DividerProps } from '@mui/material/Divider'
import { handlePassedSx } from '../../../../feature/theme/model/Sx'
import { Theme } from '@mui/material'
import { CssUnit } from '../../../../feature/theme/model/CssUnit'
import * as React from 'react'
import { AppBox, AppBoxProps } from './AppBox'

export type AppDividerProps = DividerProps & AppBoxProps & {
  thickness?: CssUnit
  component?: React.ElementType
}

export function AppDivider(props: AppDividerProps) {
  const { sx = [], orientation = 'horizontal', thickness = 1, light = true, component, ...rest } = props

  return (
    <AppBox
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      component={(dividerProps: DividerProps) => <Divider {...dividerProps} component={component} />}
      width={1}
      orientation={orientation}
      light={light}
      sx={[
        styles({ thickness, orientation, light }),
        ...handlePassedSx(sx),
      ]}
      {...rest}
    />
  )
}

const styles = ({ thickness, orientation, light }: AppDividerProps) => (theme: Theme) => {
  const thicknessInRem = theme.handleCssUnit(thickness!)

  return {
    borderColor: light ? theme.palette.grey[0.5] : theme.palette.divider,
    ...(orientation === 'vertical'
      ? { borderRightWidth: thicknessInRem, display: 'inline-block' }
      : { borderBottomWidth: thicknessInRem }
    ),
  }
}
