import { observer } from 'mobx-react-lite'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { Box, Tooltip, Typography } from '@mui/material'
import { useEffect } from 'react'
import WhatshotRoundedIcon from '@mui/icons-material/WhatshotRounded'
import TrendingDownIcon from '@mui/icons-material/TrendingDown'
import { useDashboardStore } from 'src/feature/dashboard/store/dashboard.store'
import { MetricWithValue } from '../../MetricWithValue'

export type PositionStrengthWeaknessProps = {
  levelId: string
}

export const PositionStrengthWeakness = observer(({ levelId }: PositionStrengthWeaknessProps) => {
  const dashboardStore = useDashboardStore()

  useEffect(() => {
    dashboardStore.getPositionInsightsSummary(levelId)
  }, [levelId])

  return (
    <Box sx={styles.root}>
      <AppBox sx={styles.metricsWrapper}>
        <MetricWithValue size='small' label={'Matched'} value={dashboardStore.positionInsightsSummary[levelId]?.userMatchedSkills || 0} />
        <MetricWithValue size='small' label={'Not matched'} value={dashboardStore.positionInsightsSummary[levelId]?.userNotMatchedSkills || 0} />
        <MetricWithValue size='small' label={'Not rated'} value={dashboardStore.positionInsightsSummary[levelId]?.userNotRatedSkills || 0} />
      </AppBox>
      <AppBox sx={{ flexDirection: 'row', gap: 5, alignItems: 'center', width: '100%' }}>
        <WhatshotRoundedIcon fontSize='small' sx={{ color: 'orange' }} />
        <Tooltip title={'Strength'} arrow>
          <Typography sx={{ color: 'green', width: '10em' }} noWrap>{'Strength'}</Typography>
        </Tooltip>
        <AppBox sx={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start', flexWrap: 'wrap', width: '100%' }}>
          <Tooltip title={dashboardStore.positionInsightsSummary[levelId]?.strength.join(', ') || '-'} arrow>
            <Typography sx={{ maxWidth: '7em', color: 'text.secondary' }} noWrap>
              {dashboardStore.positionInsightsSummary[levelId]?.strength.join(', ') || '-'}
            </Typography>
          </Tooltip>
        </AppBox>
      </AppBox>
      <AppBox sx={{ flexDirection: 'row', gap: 5, alignItems: 'center', width: '100%' }}>
        <TrendingDownIcon fontSize='small' color='primary' />
        <Tooltip title={'Weakness'} arrow>
          <Typography sx={{ color: 'red', width: '10em' }} noWrap>{'Weakness'}</Typography>
        </Tooltip>
        <AppBox sx={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start', flexWrap: 'wrap', width: '100%' }}>
          <Tooltip title={dashboardStore.positionInsightsSummary[levelId]?.weakness.join(', ') || '-'} arrow>
            <Typography sx={{ maxWidth: '7em', color: 'text.secondary' }} noWrap>
              {dashboardStore.positionInsightsSummary[levelId]?.weakness.join(', ') || '-'}
            </Typography>
          </Tooltip>
        </AppBox>
      </AppBox>
    </Box>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'center',
    gap: 10,
  },
  metricsWrapper: {
    flexDirection: 'row',
    gap: 13,
  },
}