import { AppNav } from '../../../component/common/analog/molecule/basic/AppNav'
import { Title2 } from '../../localization/component/atom/Title2'
import { AppSearchFieldProps, SearchField } from '../../form/component/original/SearchField'
import { AppDivider } from '../../../component/common/analog/molecule/AppDivider'
import { ChildrenProps } from '../../../model/React'
import { Translatable } from '../../localization/model/Text'
import { If } from '../../../component/common/original/atom/If'

export interface BaseSidebarProps extends ChildrenProps {
  title?: Translatable
  searchProps?: AppSearchFieldProps
  showDivider?: boolean,
  extraSpace?: boolean,
}

export function BaseSidebar(props: BaseSidebarProps) {
  const { searchProps, showDivider = true, title, extraSpace = false } = props

  return (
    <AppNav
      isStack
      direction={'column'}
      spacing={24}
      m={extraSpace ? 10 : 0}
    >
      <If condition={searchProps}>
        <SearchField {...searchProps} />
      </If>

      <If condition={title}>
        <Title2 textValue={title} m={0} variant={'h3'} />
      </If>

      {showDivider && (
        <AppDivider sx={styles.divider} />
      )}

      {props.children}
    </AppNav>
  )
}

const styles = {
  divider: {
    display: {
      xs: 'none',
      md: 'flex',
    },
  },
}
