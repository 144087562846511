import { GenericStore } from 'src/util/mobx/generic-store/generic.store'
import { createStore } from 'src/util/mobx/generic-store/store-creator'
import { UnusedSkillsList, UnusedUserSkills } from '../model/unused-skills-model'
import { GenericStoreAsyncMethod } from 'src/util/mobx/generic-store/decorator/async-method.decorator'
import { addUnusedSkills, deleteUnusedUserSkills, getUnusedSkillsList, getUnusedUserRecommendedSkills, getUnusedUserSkills, updateUnusedUserSkillLevel } from '../api/unused-skills-api'

export class unusedSkillsStore extends GenericStore {
  unusedUserSkills: UnusedUserSkills[] = []
  unUsedSkillsList: UnusedSkillsList[] = []
  unusedUserRecommendedSkills: UnusedSkillsList[] = []

  constructor() {
    super('unusedSkillsStore')

    super.observe(this)
    super.persist({ encrypt: false })
  }

  reset() {
    this.unUsedSkillsList = []
    this.unusedUserSkills = []
  }

  @GenericStoreAsyncMethod()
  async getUnusedUserSkills() {
    const data = await getUnusedUserSkills()
    this.unusedUserSkills = data
  }

  @GenericStoreAsyncMethod()
  async getUnusedSkillsList(skip: number, take: number) {
    const data = await getUnusedSkillsList(skip, take)
    this.unUsedSkillsList = data
  }

  @GenericStoreAsyncMethod()
  async addUnusedSkills(skillName: string) {
    return await addUnusedSkills(skillName)
  }

  @GenericStoreAsyncMethod()
  async deleteUnusedUserSkills(skillId: string) {
    await deleteUnusedUserSkills(skillId)
  }

  @GenericStoreAsyncMethod()
  async getUnusedUserRecommendedSkills(skip: number, take: number) {
    const data = await getUnusedUserRecommendedSkills(skip, take)
    this.unusedUserRecommendedSkills = data
  }

  @GenericStoreAsyncMethod()
  async updateUnusedUserSkillLevel(skillId: string, selectedLevel: number) {
    await updateUnusedUserSkillLevel(skillId, selectedLevel)
  }
}

export const {
  storeInstance: unusedSkillsStoreInstance,
  useStore: useUnusedSkillsStore,
} = createStore(new unusedSkillsStore())
