import { axiosInstance } from 'src/util/axios/instance.axios'
import { MyLearningProgress, OrganisationLevelOverview, OverallCourseOverview, OverallLearningProgress, PositionInsights, skillGapMetrics, SkillSetList, TeamCourseOverview, TeamMetrics, UserCourses, UserMetrics, UserStatusMetrics } from '../model/dashboard-model'
import { CourseListItem } from 'src/feature/e-learning/model/ELearningCourseListItem'

export async function getOrganisationLevelOverview(): Promise<OrganisationLevelOverview[]> {
  const { data } = await axiosInstance.request<OrganisationLevelOverview[]>({
    url: '/dashboard/organisation-overview',
  })

  return data
}

export async function getOverallLearningProgress(days?: number): Promise<OverallLearningProgress> {
  const { data } = await axiosInstance.request<OverallLearningProgress>({
    url: `/dashboard/overall-learning-progress/${days || 15}`,
  })

  return data
}

export async function getOverallCourseOverview(): Promise<OverallCourseOverview> {
  const { data } = await axiosInstance.request<OverallCourseOverview>({
    url: '/dashboard/overall-course-overview',
  })

  return data
}

export async function getTeamCourseOverview(): Promise<TeamCourseOverview> {
  const { data } = await axiosInstance.request<TeamCourseOverview>({
    url: `/dashboard/teamcourse-overview`,
  })

  return data
}

export async function getTeamOverview(levelId: string): Promise<TeamMetrics> {
  const { data } = await axiosInstance.request<TeamMetrics>({
    url: `/dashboard/team-overview/${levelId}`,
  })

  return data
}

export async function getUserOverview(levelId: string, userId: string): Promise<UserMetrics> {
  const { data } = await axiosInstance.request<UserMetrics>({
    url: `/dashboard/user-overview/${levelId}/${userId}`,
  })

  return data
}

export async function getDepartmentCourses(levelId: string): Promise<CourseListItem[]> {
  const { data } = await axiosInstance.request<CourseListItem[]>({
    url: `/dashboard/department-courses/${levelId}`,
  })

  return data
}

export async function getSkillGapOverview(setIds: string[]): Promise<skillGapMetrics[]> {
  const { data } = await axiosInstance.request<skillGapMetrics[]>({
    url: `/skill-assessment/skill-gap/`,
    method: 'POST',
    data: { setIds },
  })

  return data
}

export async function getUserCourses(userId: string): Promise<UserCourses> {
  const { data } = await axiosInstance.request<UserCourses>({
    url: `/dashboard/user-courses/${userId}`,
  })

  return data
}

export async function getUserStatus(userId: string): Promise<UserStatusMetrics[]> {
  const url = userId ? `/dashboard/user-status/${userId}` : '/dashboard/user-status'
  const { data } = await axiosInstance.request<UserStatusMetrics[]>({
    url,
  })

  return data
}

export async function downloadReport(levelId?: string): Promise<any> {
  const url = levelId ? `/dashboard/download-report/${levelId}` : '/dashboard/download-report/'
  const { data } = await axiosInstance.request<any>({
    url,
    responseType: 'arraybuffer',
  })

  return data
}

export async function getAllSkills(orgLevelIds: string[], skip: number, take: number): Promise<SkillSetList> {
  const { data } = await axiosInstance.request<SkillSetList>({
    url: `/skill-assessment/all-skills/?skip=${skip}&take=${take}`,
    method: 'POST',
    data: { orgLevelIds },
  })

  return data
}

export async function getPositionInsightsSummary(levelId: string): Promise<PositionInsights> {
  const { data } = await axiosInstance.request<any>({
    url: `/skill-assessment/position-insights/${levelId}`,
  })

  return data
}

export async function getMyOverallLearningProgress(days?: number): Promise<MyLearningProgress> {
  const { data } = await axiosInstance.request<MyLearningProgress>({
    url: (days && days !== 0) ? `/dashboard/my-learning-progress/${days}` : `/dashboard/my-learning-progress`,
  })

  return data
}
