import { Image, ImageProps } from '../../../../feature/image/component/Image'
import { Theme } from '@mui/material'
import { Word } from '../../../../feature/localization/component/atom/Word'
import { AppBoxProps } from '../../analog/molecule/AppBox'
import { TranslatableTextProps } from '../../../../feature/localization/component/atom/TranslatableText'
import { handlePassedSx } from '../../../../feature/theme/model/Sx'
import { AppUnstyledButton } from '../../analog/molecule/button/AppUnstyledButton'
import { SxHelperStyle } from '../../../../feature/theme/style/sx-helper.style'

interface Props {
  rootProps: AppBoxProps
  imageProps: ImageProps
  textProps?: TranslatableTextProps
}

export function IconButtonWithText(props: Props) {
  const {
    rootProps: {
      sx: rootSx = [],
      ...restRootProps
    } = {},
    imageProps: {
      outerProps: {
        sx: imageOuterSx = [],
        ...restImageOuterProps
      } = {},
      innerProps: {
        sx: imageInnerSx = [],
        ...restImageInnerProps
      } = {},
      src = '',
      alt = '',
      ...restImageProps
    } = {},
    textProps: {
      sx: textSx = [],
      ...restTextProps
    } = {},
  } = props

  return (
    <AppUnstyledButton
      alignItems={'center'}
      sx={[
        styles.root,
        ...handlePassedSx(rootSx),
      ]}
      {...restRootProps}
    >
      <Image
        src={src}
        alt={alt}
        imageSize={'unset'}
        outerProps={{
          sx: [
            styles.imageWrapper,
            ...handlePassedSx(imageOuterSx),
          ],
          ...restImageOuterProps,
        }}
        innerProps={{
          sx: [
            styles.image,
            ...handlePassedSx(imageInnerSx),
          ],
          ...restImageInnerProps,
        }}
        {...restImageProps}
      />
      <Word
        sx={[
          styles.text,
          ...handlePassedSx(textSx),
        ]}
        variant={'body2'}
        {...restTextProps}
      />
    </AppUnstyledButton>
  )
}

const styles = {
  root: ({ handleCssUnit }: Theme) => ({
    ...SxHelperStyle.flexCenterX,
    fontSize: handleCssUnit(25),
  }),
  imageWrapper: ({ palette }: Theme) => ({
    ...SxHelperStyle.flexCenter,
    borderRadius: '100%',
    width: '1em',
    height: '1em',
    backgroundColor: palette.secondary.main,
    mr: '0.3em',
  }),
  image: {
    width: '0.56em',
  },
  text: ({ palette }: Theme) => ({
    margin: 0,
    color: palette.text.primary,
    fontWeight: 500,
  }),
}
