import { AppTextField } from 'src/feature/form/component/analog/AppTextField'
import { observer } from 'mobx-react-lite'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import SearchIcon from '@mui/icons-material/Search'
import { SxProps, Theme } from '@mui/material/styles'

export type AppSearchBarProps = {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  sx?: SxProps<Theme>
}

export const AppSearchBar = observer(({ onChange, onFocus, onBlur, sx }: AppSearchBarProps) => {
  const { translation } = useLocalizationStore()
  const texts = translation.pages.eLearning

  return (
    <AppTextField
      placeholder={texts.search()}
      sx={{ mb: 0, fontSize: 14 }}
      InputProps={{
        sx: { ...styles.field, ...sx },
        endAdornment: (
          <SearchIcon fontSize='small' sx={{ color: 'text.secondary' }} />
        ),
      }}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  )
})

const styles = {
  field: {
    py: '0.7em',
  },
}