import { observer } from 'mobx-react-lite'
import { AppBox } from '../AppBox'
import IconButton from '@mui/material/IconButton'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import { EmblaCarouselType } from 'embla-carousel-react'
import { useState, useEffect, useCallback } from 'react'

export const CarouselButtons = observer(({ emblaApi }: { emblaApi?: EmblaCarouselType }) => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true)

  const scrollPrev = useCallback(
    () => emblaApi && emblaApi.scrollPrev(),
    [emblaApi],
  )
  const scrollNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi],
  )
  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev())
    setNextBtnDisabled(!emblaApi.canScrollNext())
  }, [])

  useEffect(() => {
    if (!emblaApi) {
      return
    }

    onSelect(emblaApi)
    emblaApi.on('reInit', onSelect)
    emblaApi.on('select', onSelect)
  }, [emblaApi, onSelect])

  return (
    <AppBox sx={styles.root}>
      <IconButton size='small' sx={{
        ...styles.button,
        '&:hover': {
          transform: 'translateX(-3px)',
        },
      }} onClick={scrollPrev} disabled={prevBtnDisabled}>
        <ArrowBackRoundedIcon fontSize='small' />
      </IconButton>
      <IconButton size='small' sx={{
        ...styles.button,
        '&:hover': {
          transform: 'translateX(3px)',
        },
      }} onClick={scrollNext} disabled={nextBtnDisabled}>
        <ArrowForwardRoundedIcon fontSize='small' />
      </IconButton>
    </AppBox>
  )
})

const styles = {
  root: {
    display: 'flex',
    position: 'absolute',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    pointerEvents: 'none',
    padding: '10px 5px',
  },
  button: {
    backgroundColor: 'background.paper',
    color: 'text.primary',
    border: '1px solid',
    borderColor: 'text.primary',
    opacity: 0.75,
    pointerEvents: 'auto',
    borderRadius: '50%',
    transition: 'transform 0.3s ease, color 0.3s ease, backgroundColor 0.3s ease',
    '&.Mui-disabled': {
      color: 'text.secondary',
      borderColor: 'text.secondary',
      cursor: 'not-allowed',
      opacity: 0.75,
      pointerEvents: 'auto',
    },
  },
}
