import { Box, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { AppButton } from 'src/component/common/analog/molecule/button/AppButton'
import { useNavigate } from 'react-router-dom'
import { appRoutePath } from 'src/feature/router/util/app-route-path'
import { Image } from 'src/feature/image/component/Image'
import AssignCourseBanner from '../../../feature/image/asset/assign_course_banner.png'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { Palette } from 'src/feature/theme/model/Palette'

export const RecommendedCategories = observer(() => {
  const { translation } = useLocalizationStore()
  const navigate = useNavigate()

  const translations = translation.pages.myGrowth

  const handleShowMoreClick = useCallback(() => {
    navigate(appRoutePath.learning)
  }, [navigate])

  return (
    <Box sx={styles.bannerWrapper}>
      <AppBox sx={styles.textWrapper}>
        <Typography sx={{ color: Palette.WHITE, fontSize: 40 }}>{translations.outOfCoursesHeader()}</Typography>
        <Typography sx={{ width: { xs: '100%', md: '80%', lg: '50%' }, color: Palette.WHITE }}>{translations.outOfCoursesDesc()}</Typography>
        <AppButton fontSize={12} variant='light' size='small' onClick={handleShowMoreClick}>{translation.buttons.showMore()}</AppButton>
      </AppBox>
      <Image
        src={AssignCourseBanner}
        alt='Assigned course banner'
        outerProps={{
          sx: styles.bannerImage,
        }}
      />
    </Box>
  )
})

const styles = {
  bannerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 15,
    position: 'relative',
    height: 500,
    overflow: 'hidden',
    textAlign: 'center',
  },
  textWrapper: {
    position: 'relative',
    zIndex: 2,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    padding: '1rem',
    gap: 10,
  },
  bannerImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    zIndex: 1,
  },
}
