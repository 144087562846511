import { Box } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { GoBackButtonWithArrow } from 'src/component/common/original/molecule/GoBackButtonWithArrow'
import { UserSkillGapAnalysis } from '../component/UserSkillGapAnalysis'
import { UserCourses } from '../component/UserCourses'
import { useDashboardStore } from '../store/dashboard.store'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { useUserStore } from 'src/feature/user/store/user.store'

export type AdministratorUserDetailsPageProps = {
  userId: string
}

export const AdministratorUserDetailsPage = observer(({ userId }: AdministratorUserDetailsPageProps) => {
  const { translation } = useLocalizationStore()
  const translations = translation.pages.myTeam
  const dashboardStore = useDashboardStore()
  const userStore = useUserStore()

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', padding: '1rem' }}>
      <AppBox sx={styles.backButtonWrapper}>
        <GoBackButtonWithArrow textValue={`${translations.teamHeader()} > ${translation.pages.controlpanelUsers.title()} > ${dashboardStore.userOverview?.[userId]?.userName}`} />
      </AppBox>
      <AppBox sx={{ flexDirection: 'column', gap: '4rem' }}>
        {(userStore.sitemap?.modules ?? []).filter(s => s.key === 'skillGap').length > 0 && <UserSkillGapAnalysis userId={userId} />}
        <UserCourses userId={userId} />
      </AppBox>
    </Box>
  )
})

const styles = {
  backButtonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
  },
}
