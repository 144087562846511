import { observer } from 'mobx-react-lite'
import { CategoryList } from '../component/organism/CategoryList'
import { Box } from '@mui/material'

export const LearningOverviewPage = observer(() => {
  return (
    <Box sx={styles.root}>
      <CategoryList />
    </Box>
  )
})

const styles = {
  root: {
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    gap: '3rem',
    padding: '1rem 1rem',
  },
}