import { Box, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useDashboardStore } from 'src/feature/dashboard/store/dashboard.store'
import { useEffect, useState } from 'react'
import { OverallMetrics } from '../organisms/OverallMetrics'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { OverallMetricsWithValue } from '../molecules/OverallMetricsWithValue'
import { AppPaper } from 'src/component/common/analog/molecule/AppPaper'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { LearningProgressChart } from '../organisms/LearningProgressChart'
import { OverallCourseOverview } from '../organisms/OverallCourseOverview'

export const OrganisationInsights = observer(() => {
  const { translation } = useLocalizationStore()
  const dashboardStore = useDashboardStore()
  const { isLoading: TeamCourseLoading } = dashboardStore.getGenericFieldsState('getTeamCourseOverview')
  const { isLoading: OverallLearingLoading } = dashboardStore.getGenericFieldsState('getOverallLearningProgress')

  const translations = translation.pages.myTeam
  const isLoading = TeamCourseLoading || OverallLearingLoading
  const [days, setDays] = useState<number>(7)

  useEffect(() => {
    dashboardStore.getTeamCourseOverview()
    dashboardStore.getOverallLearningProgress(days)
  }, [])

  const handleSetDays = (days: number) => {
    setDays(days)
    dashboardStore.getOverallLearningProgress(days)
  }

  return (
    <Box sx={styles.root}>
      <Typography variant={'h4'}>{'Organisation Insights'}</Typography>
      <AppBox sx={{ display: 'flex', flexDirection: 'row', gap: 7 }}>
        <AppBox sx={{ flexDirection: 'column', gap: 7 }}>
          <AppBox sx={{ display: 'flex', flexDirection: 'row', gap: 7 }}>
            <OverallMetrics
              isLoading={isLoading}
              userList={dashboardStore.teamCourseOverview?.totalUserList || []}
              label={translations.metricsHeader.totalUsers()}
            />
            <OverallMetrics
              isLoading={isLoading}
              userList={dashboardStore.teamCourseOverview?.activeUserList || []}
              label={translations.metricsHeader.activeUsers()}
            />
            <OverallMetrics
              isLoading={isLoading}
              userList={dashboardStore.teamCourseOverview?.inactiveUserList || []}
              label={translations.metricsHeader.inactiveUsers()}
            />
          </AppBox>
          <AppBox sx={{ display: 'flex', flexDirection: 'row', gap: 7 }}>
            <AppPaper sx={styles.metricsWrapper}>
              <OverallMetricsWithValue
                isLoading={isLoading}
                size={dashboardStore.overallLearningProgress?.totalActiveCourses || 0}
                label={'Total active courses'}
              />
            </AppPaper>
            <AppPaper sx={styles.metricsWrapper}>
              <OverallMetricsWithValue
                isLoading={isLoading}
                size={dashboardStore.overallLearningProgress?.totalCoursesCompleted || 0}
                label={'Total courses completed'}
              />
            </AppPaper>
            <AppPaper sx={styles.metricsWrapper}>
              <OverallMetricsWithValue
                isLoading={isLoading}
                size={dashboardStore.overallLearningProgress?.totalCoursesInProgress || 0}
                label={'Total courses in progress'}
              />
            </AppPaper>
          </AppBox>
        </AppBox>
        <AppPaper sx={{ ...styles.metricsWrapper, width: 440, height: 227 }}>
          <LearningProgressChart days={days} setDays={handleSetDays} chartData={dashboardStore.overallLearningProgress?.chartData || []} />
        </AppPaper>
      </AppBox>
      <OverallCourseOverview />
    </Box>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 7,
  },
  metricsWrapper: {
    gap: 7,
    padding: 15,
    borderRadius: '20px',
    cursor: 'pointer',
    width: 160,
    height: 110,
  },
}
