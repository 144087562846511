import { observer } from 'mobx-react-lite'
import { Collapse, Fade, Typography, useTheme } from '@mui/material'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { useSkillAssessmentStore } from '../../skill-assessment/store/skill-assessment.store'
import { useEffect, useState } from 'react'
import { SkillSet } from '../../skill-assessment/model/skill-assessment-model'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { AppCircularRating } from 'src/component/common/analog/molecule/AppCircularRating'
import { AppPaper } from 'src/component/common/analog/molecule/AppPaper'
import { SkillAssessmentLevels } from 'src/feature/skill-assessment/component/SkillAssessmentLevels'
import { AppConfetti } from 'src/component/common/analog/molecule/confetti/AppConfetti'
import { AppButton } from 'src/component/common/analog/molecule/button/AppButton'

export const SkillGapAnalysis = observer(() => {
  const { translation } = useLocalizationStore()
  const skillAssessmentStore = useSkillAssessmentStore()
  const theme = useTheme()

  const translations = translation.pages.myTeam
  const [open, setOpen] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [skillAssessmentSetIndex, setSkillAssessmentSetIndex] = useState<number>(0)
  const [isAllSkillsRated, setIsAllSkillsRated] = useState<boolean>(false)

  useEffect(() => {
    skillAssessmentStore.reset()
    skillAssessmentStore.getSkillSets().then(() => {
      if (skillAssessmentStore.skillSets && skillAssessmentStore.skillSets?.skills?.length !== 0) {
        setOpen(true)
      } else {
        setOpen(false)
      }
    })
  }, [])

  const checkIfAllSkillsAreRated = () => {
    const allSkillsRated: boolean = skillAssessmentStore?.skillSets?.skills?.every(skill => skill.selectedSetLevel !== null) ?? false
    
    return allSkillsRated
  }

  const handleRateSkillSetClick = (selectedSkillSetIndex: number) => {
    setSkillAssessmentSetIndex(selectedSkillSetIndex)
    setIsModalOpen(true)
  }

  return (
    <>
      <AppConfetti show={isAllSkillsRated} />
      <Collapse in={open} timeout={500} mountOnEnter unmountOnExit>
        <AppPaper sx={styles.root}>
          <Typography variant='h1' m={0} sx={{ color: 'text.primary' }}>{skillAssessmentStore.skillSets?.category_name}</Typography>
          <Typography sx={{ width: { xs: '100%', md: '75%', lg: '60%' }, textAlign: 'center', color: 'text.secondary' }}>{skillAssessmentStore.skillSets?.category_description}</Typography>
          <AppBox sx={styles.ratingWrapper}>
            {skillAssessmentStore.skillSets?.skills?.map((skill: SkillSet, index: number) => (
              <AppCircularRating
                size={170}
                key={skill.id}
                indexKey={index}
                userRating={skill.selectedSetLevel}
                expectedRating={skill.expected_set_level}
                label={skill.setName}
                showRating={checkIfAllSkillsAreRated()}
                showLevelBtn={true}
                onRateSkillClick={handleRateSkillSetClick}
                viewLabel='UserView'
                defaultColor={theme.palette.background.dark}
              />
            ))}
          </AppBox>
          <AppButton size='small' fontSize={12} color='primary' variant='contained' onClick={() => handleRateSkillSetClick(0)}>{checkIfAllSkillsAreRated() ? 'Update' : 'Rate'}</AppButton>
          <Fade in={!checkIfAllSkillsAreRated()} timeout={700} mountOnEnter unmountOnExit>
            <Typography sx={{ width: { xs: '100%', md: '75%', lg: '60%' }, textAlign: 'center', color: 'text.secondary' }}>{translations.errorMsg.rateAllSkills()}</Typography>
          </Fade>
          <Fade in={checkIfAllSkillsAreRated()} timeout={700} mountOnEnter unmountOnExit>
            <Typography sx={{ width: { xs: '100%', md: '75%', lg: '60%' }, textAlign: 'center', color: 'text.secondary' }}>{translation.pages.myGrowth.skillGapAnalysisFinished()}</Typography>
          </Fade>
          {isModalOpen && <SkillAssessmentLevels
            skillAssessmentSetIndex={skillAssessmentSetIndex}
            isModalOpen={isModalOpen}
            isModalClose={() => {
              setIsModalOpen(false)
              setIsAllSkillsRated(checkIfAllSkillsAreRated())
            }}
          />}
        </AppPaper>
      </Collapse>
    </>
  )
})

const styles = {
  root: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 20,
    margin: '0 1rem',
    padding: '2rem',
    borderRadius: '12px',
  },
  ratingWrapper: {
    display: 'grid',
    gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(5, 1fr)' },
    justifyContent: 'center',
    rowGap: '1.5rem',
    columnGap: '3rem',
  },
}