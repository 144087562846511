import { Box } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Title1 } from 'src/feature/localization/component/atom/Title1'
import { PositionInsightCards } from '../organisms/PositionInsightCards'

export const PositionInsights = observer(() => {
  return (
    <Box sx={styles.root}>
      <Title1>Position insights</Title1>
      <PositionInsightCards />
    </Box>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    overflow: 'hidden',
    gap: 20,
  },
}
