import { observer } from 'mobx-react-lite'
import { ELearningCourseCategory } from '../../model/ELearningCourseCategory'
import { Chip, Fade } from '@mui/material'
import { startCase } from 'lodash'

export type CategoryItemProp = {
  item: ELearningCourseCategory
  index: number
  selectedSubCategory: string
  clickHandler?: (item: ELearningCourseCategory) => void
}

export const CourseItem = observer(({ item, index, selectedSubCategory, clickHandler }: CategoryItemProp) => {
  return (
    <Fade in={true} timeout={index * 100} mountOnEnter unmountOnExit>
      <Chip
        key={index}
        variant='outlined'
        sx={{ ...styles.chip, ...(selectedSubCategory !== item.id ? '' : { backgroundColor: 'text.secondary' }) }}
        label={startCase(item.titleLocale)}
        onClick={() => clickHandler && clickHandler(item)}
      />
    </Fade>
  )
})

const styles = {
  chip: {
    padding: '0.5rem 0.75rem',
    backgroundColor: 'background.paper',
    border: 'none',
    ':active': {
      boxShadow: 'unset !important',
    },
    fontSize: 12,
  },
}
