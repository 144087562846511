import { observer } from 'mobx-react-lite'
import { useDashboardStore } from '../store/dashboard.store'
import { Box, Chip, Icon, Tab, Tabs, Typography } from '@mui/material'
import { Title1 } from 'src/feature/localization/component/atom/Title1'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { useEffect, useState } from 'react'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { DepartmentRoleCard } from './DepartmentRoleCard'
import { iconMapping } from 'src/util/icon-mapping'
import { appRoutePath } from 'src/feature/router/util/app-route-path'
import { useNavigate } from 'react-router-dom'
import { useAuthStore } from 'src/feature/auth/store/auth.store'
import { OrganisationRole } from 'src/feature/control-panel/model/OrganisationRole'
import { AppProgressLine } from 'src/component/common/analog/molecule/loader/AppProgressLine'

export const DepartmentList = observer(() => {
  const { translation } = useLocalizationStore()
  const dashboardStore = useDashboardStore()
  const navigate = useNavigate()
  const authStore = useAuthStore()

  const translations = translation.pages.myTeam

  const [selectedSort, setSelectedSort] = useState<string>('')
  const [selectedRoleList, setSelectedRoleList] = useState<any[]>([])
  const [selectedLevel, setSelectedLevel] = useState<string>('all')
  
  const { isLoading: teamOverview } = dashboardStore.getGenericFieldsState('getTeamCourseOverview')

  useEffect(() => {
    getAllRoles()
  }, [])

  const getAllRoles = () => {
    const roleList = dashboardStore.organisationLevelOverview?.filter(level => level.levelType === 'role') || []
    roleList?.length > 0 && setSelectedRoleList(roleList)
  }

  const setSelectedFilter = (label: string) => {
    if (selectedSort === label) {
      setSelectedSort('')
    } else {
      setSelectedSort(label)
      switch (label) {
      case translations.sortHeader.activeUsers():
        selectedRoleList?.sort((a, b) => dashboardStore.teamOverview[b.id].activeUsers - dashboardStore.teamOverview[a.id].activeUsers)
        break
      case translations.sortHeader.inactiveUsers():
        selectedRoleList?.sort((a, b) => dashboardStore.teamOverview[a.id].activeUsers - dashboardStore.teamOverview[b.id].activeUsers)
        break
      default:
        break
      }
    }
  }

  const handleDepartmentChange = (_: React.SyntheticEvent, newValue: string) => {
    setSelectedRoleList([])
    setSelectedSort('')
    if (newValue === 'all') {
      getAllRoles()
    } else {
      const roleList = dashboardStore.organisationLevelOverview?.filter(level => (level.levelType === 'role' && level.parentLevel === newValue)) || []
      setSelectedRoleList(roleList)
    }
    setSelectedLevel(newValue)
  }
  
  return (
    <Box sx={styles.cardWrapper}>
      <AppProgressLine sx={{ width: '5rem' }} isShowing={teamOverview}></AppProgressLine>
      <AppBox sx={{ flexDirection: 'column', gap: '1rem', alignItems: 'center' }}>
        <Title1>{translations.teamDevelopmentHeader()}</Title1>
        <Typography
          sx={{ textAlign: 'center', color: 'text.secondary' }}
        >{translations.teamDevelopmentDesc()}</Typography>
      </AppBox>
      {(dashboardStore.organisationLevelOverview?.length ?? 0) === 0 && <AppBox sx={{ flexDirection: 'column', alignItems: 'center', color: 'text.secondary', gap: 5 }}>
        {authStore.hasRole(OrganisationRole.SystemAdministrator) && <>
          <Typography>{translations.noSubteamsMsg()}</Typography>
          <Typography sx={{ fontWeight: 500, cursor: 'pointer', color: 'secondary.main' }} onClick={() => navigate(appRoutePath.controlpanelOrganisation)}>{translation.pages.myTeam.redirectionMessage()}</Typography>
        </>}
        {!authStore.hasRole(OrganisationRole.SystemAdministrator) && <>
          <Typography>{translations.managerNoSubteamsMsg()}</Typography>
        </>}
      </AppBox>}
      {(dashboardStore.organisationLevelOverview?.length ?? 0) > 0 && <AppBox sx={styles.depWrapper}>
        <Tabs
          value={selectedLevel}
          onChange={handleDepartmentChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          sx={{ backgroundColor: 'background.paper', height: '3.2rem', alignItems: 'center', borderRadius: '16px' }}
        >
          <Tab
            key={'all'}
            label={'All'}
            value={'all'}
            sx={{ color: 'text.primary', fontSize: '1rem', fontWeight: 500 }}
          />
          {dashboardStore.organisationLevelOverview?.map(levelId => (levelId.levelType === 'team' && <Tab
            key={levelId.key}
            label={levelId.name}
            value={levelId.id}
            sx={{ color: 'text.primary', fontSize: '1rem', fontWeight: 500 }}
          />))}
        </Tabs>
      </AppBox>}
      {(dashboardStore.organisationLevelOverview?.length ?? 0) > 0 && (selectedRoleList?.length ?? 0) === 0 && <AppBox sx={{ flexDirection: 'column', alignItems: 'center', color: 'text.secondary', gap: 5 }}>
        {authStore.hasRole(OrganisationRole.SystemAdministrator) && <>
          <Typography>{translations.noRolesMsg()}</Typography>
          <Typography sx={{ fontWeight: 500, cursor: 'pointer', color: 'secondary.main' }} onClick={() => navigate(appRoutePath.controlpanelOrganisation)}>{translation.pages.myTeam.redirectionMessage()}</Typography>
        </>}
        {!authStore.hasRole(OrganisationRole.SystemAdministrator) && <>
          <Typography>{translations.managerNoRolesMsg()}</Typography>
        </>}
      </AppBox>}
      {(dashboardStore.organisationLevelOverview?.length ?? 0) > 0 && (selectedRoleList?.length ?? 0) > 0 && <>
        <AppBox sx={styles.chipWrapper}>
          <Typography sx={{ fontSize: '1.1em' }}>{translations.sortHeader.sortBy()}</Typography>
          <Box sx={styles.chipContainer}>
            {[{ title: translations.sortHeader.activeUsers(), icon: 'HowToRegRoundedIcon' }, { title: translations.sortHeader.inactiveUsers(), icon: 'PersonOffRoundedIcon' }].map(label => (
              <Chip
                key={label.title}
                variant='outlined'
                icon={<Icon sx={{ color: 'secondary.main', height: '18px', width: '18px' }} color='inherit' component={iconMapping[label.icon]} />}
                sx={{ ...styles.chip, ...(selectedSort !== label.title ? '' : { borderColor: 'secondary.main' }) }}
                label={label.title}
                onClick={() => setSelectedFilter(label.title)}
              />
            ))}
          </Box>
        </AppBox>
        <AppBox sx={styles.cardContainer}>
          {(selectedRoleList?.length ?? 0) > 0 && selectedRoleList?.map(levelId => (
            <DepartmentRoleCard key={levelId.key} levelId={levelId.id} />
          ))}
        </AppBox>
      </>}
    </Box>
  )
})

const styles = {
  cardWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '2rem',
  },
  depWrapper: {
    width: '100%',
    justifyContent: 'center',
  },
  chipWrapper: {
    width: '100%',
    flexDirection: { xs: 'column', md: 'row' },
    gap: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  chipContainer: {
    display: 'flex',
    gap: 10,
    justifyContent: { xs: 'flex-start', md: 'flex-end' },
  },
  chip: {
    padding: '0.5rem 0.75rem',
    transition: 'transform 0.3s',
    '&:hover': {
      backgroundColor: 'unset !important',
      borderColor: 'secondary.main',
      transform: 'scale(1.03)',
    },
    ':active': {
      boxShadow: 'unset !important',
    },
  },
  cardContainer: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 13,
    justifyContent: 'center',
  },
}
