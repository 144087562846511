import { Drawer, useTheme } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { AppIconButton } from 'src/component/common/analog/molecule/button/AppIconButton'
import { MainLinks } from 'src/feature/header/component/original/organism/MainLinks'
import KeyboardDoubleArrowLeftRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftRounded'
import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded'
import { ControlPanelSidebar } from 'src/feature/control-panel/component/ControlPanelSidebar'
import { useUserStore } from 'src/feature/user/store/user.store'

export type WithDrawerlayoutProps = {
  drawerWidth: number
  isOpen: boolean
  onToggle: () => void
}

export const WithDrawerlayout = observer(({ drawerWidth, isOpen, onToggle }: WithDrawerlayoutProps) => {
  const theme = useTheme()
  const userStore = useUserStore()

  return (
    <Drawer
      open={isOpen}
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: 'border-box',
          borderRight: 'none',
          padding: `${theme.spacing(70)} 1.5em 1.5em 1.5em`,
          height: '100%',
          alignItems: isOpen ? 'flex-start' : 'center',
          justifyContent: 'space-between',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.standard,
          }),
          overflowX: 'hidden',
        },
      }}
    >
      <AppBox
        sx={{
          flexDirection: 'column',
          gap: theme.spacing(15),
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <MainLinks isOpen={isOpen} />
        {(userStore.sitemap?.modules ?? []).filter(s => s.key === 'controlpanel' && s.level === 'admin').length > 0 && <ControlPanelSidebar isOpen={isOpen} />}
      </AppBox>
      <AppIconButton sx={{ alignSelf: isOpen ? 'start' : 'center' }} onClick={onToggle}>
        {isOpen ? <KeyboardDoubleArrowLeftRoundedIcon sx={{
          ...styles.icon,
          '&:hover': {
            transform: 'translateX(-3px)',
          },
        }} /> : <KeyboardDoubleArrowRightRoundedIcon sx={{
          ...styles.icon,
          '&:hover': {
            transform: 'translateX(3px)',
          },
        }} />}
      </AppIconButton>
    </Drawer>
  )
})

const styles = {
  icon: {
    color: 'text.primary',
    transition: 'color 0.3s ease, transform 0.3s ease',
    height: 22,
    width: 22,
  },
}