import { YohrsUser } from '../feature/user/model/User'
import { EditProfileFormModel } from '../feature/profile/model/EditProfileForm'
import { createStore } from '../util/mobx/generic-store/store-creator'
import { userStoreInstance } from '../feature/user/store/user.store'
import { contentfulStoreInstance } from '../feature/contentful/store/contentful.store'
import { hubSpotStoreInstance } from '../feature/hub-spot/store/hub-spot.store'
import { authStore } from '../feature/auth/store/auth.store'
import { companyLinks } from '../util/link'
import { SignInFormModel } from '../feature/auth/model/SignIn'
import { eLearningStoreInstance } from '../feature/e-learning/store/e-learning.store'
import { maintenanceStoreInstance } from '../feature/maintenance/store/maintenance.store'
import { paymentStoreInstance } from '../feature/payment/store/payment.store'
import { GenericStoreAsyncMethod } from '../util/mobx/generic-store/decorator/async-method.decorator'
import { GenericStore } from '../util/mobx/generic-store/generic.store'
import { themeStoreInstance } from '../feature/theme/store/theme.store'
import { localizationStoreInstance } from '../feature/localization/store/localization.store'
import { payrollStoreInstance } from '../feature/payroll/store/payroll.store'
import { forumStoreInstance } from '../feature/forum/store/forum.store'
import lodashIsEqual from 'lodash/isEqual'
import { deleteCurrentUser, resendVerfication, uploadAvatar } from '../feature/user/api/user'
import { getBase64FromFile } from '../model/File'
import { goHome } from '../feature/router/util/router.helper'
import { notificationStoreInstance } from '../feature/notification/store/notification.store'
import { controlPanelStoreInstance } from 'src/feature/control-panel/store/control-panel.store'
import { companyPageStoreInstance } from 'src/feature/company-page/store/company-page.store'
import { skillAssessmentStoreInstance } from 'src/feature/skill-assessment/store/skill-assessment.store'
import { dashboardStoreInstance } from 'src/feature/dashboard/store/dashboard.store'
import { unusedSkillsStoreInstance } from 'src/feature/unused-skills/store/unused-skills.store'

// update
export class MainStore extends GenericStore {
  isGlobalLoader!: boolean

  constructor() {
    super('MainStore')

    super.observe(this)
    this.reset()
    super.persist({ encrypt: false })
  }

  reset() {
    this.isGlobalLoader = true
    this.resetOtherStores()
  }

  resetOtherStores() {
    authStore.reset()
    contentfulStoreInstance.reset()
    userStoreInstance.reset()
    hubSpotStoreInstance.reset()
    eLearningStoreInstance.reset()
    maintenanceStoreInstance.reset()
    themeStoreInstance.reset()
    localizationStoreInstance.reset()
    paymentStoreInstance.reset()
    payrollStoreInstance.reset()
    forumStoreInstance.reset()
    notificationStoreInstance.reset()
    controlPanelStoreInstance.reset()
    companyPageStoreInstance.reset()
    skillAssessmentStoreInstance.reset()
    dashboardStoreInstance.reset()
    unusedSkillsStoreInstance.reset()
  }

  async handleSubscriptionCancel() {
    await deleteCurrentUser()
  }

  shouldUpdateHubSpotContact() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const userData = userStoreInstance.userData! || {} as YohrsUser
    const hubSpotProperties = hubSpotStoreInstance?.contactProperties || {}

    const { email, firstName, lastName, phoneNumber } = userData
    const yohrsProperties = ({ email, firstName, lastName, phoneNumber })

    return !lodashIsEqual(yohrsProperties, hubSpotProperties)
  }

  @GenericStoreAsyncMethod({ withAllLoading: false, throwOnError: false })
  async handleUpdateHubSpotContact(data: Partial<YohrsUser>) {
    if (this.shouldUpdateHubSpotContact()) {
      await hubSpotStoreInstance.handleUpdateOrCreateContact(data)
    }
  }

  @GenericStoreAsyncMethod({ withAllLoading: false })
  async handleEditProfile(data: EditProfileFormModel, avatar?: File) {
    await userStoreInstance.handleUpdateYohrsUser(data)

    if (avatar) {
      await uploadAvatar(avatar)
      userStoreInstance.avatar = await getBase64FromFile(avatar)
    }

    await this.handleUpdateHubSpotContact(data)
  }

  @GenericStoreAsyncMethod({ withAllLoading: false })
  async handleResendVerification() {
    await resendVerfication()
  }

  @GenericStoreAsyncMethod()
  async handleGotoCompanyWebsiteHome() {
    this.isGlobalLoader = true
    const companyWebsiteHomeLink = companyLinks.website.home

    window.location.href = companyWebsiteHomeLink

    if (window.location.href === companyWebsiteHomeLink) {
      this.endLoading()
    }
  }

  @GenericStoreAsyncMethod()
  async handleGotoCompanyWebsiteTerms() {
    this.isGlobalLoader = true
    const companyWebsiteTermsLink = companyLinks.website.terms

    window.location.href = companyWebsiteTermsLink

    if (window.location.href === companyWebsiteTermsLink) {
      this.endLoading()
    }
  }

  @GenericStoreAsyncMethod()
  async handleAppSignIn(values: SignInFormModel) {
    this.isGlobalLoader = false
    await authStore.handleSignIn(values)

    authStore.isOpenAppViaAuth = true
    await hubSpotStoreInstance.handleUpdateOrCreateContact({ email: values.email })
    await userStoreInstance.handleSetAvatar()
  }

  @GenericStoreAsyncMethod()
  async handleResendLink() {
    return authStore.handleResendLink()
  }

  @GenericStoreAsyncMethod({ throwOnError: false })
  async handleSignOutApp() {
    this.isGlobalLoader = true
    await authStore.handleSignOut()
    this.reset()
    goHome()
  }
}

export const {
  storeInstance: mainStoreInstance,
  useStore: useMainStore,
  StoreProvider: MainStoreProvider,
} = createStore(new MainStore())
