import { Box, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import {
  ResponsiveContainer,
  Tooltip,
  AreaChart,
  XAxis,
  Area,
  ReferenceDot,
} from 'recharts'
import { lineChartData } from 'src/feature/dashboard/model/dashboard-model'
import { format } from 'date-fns'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { AppButton } from 'src/component/common/analog/molecule/button/AppButton'

export type LearningProgressChartProps = {
  chartData: lineChartData[]
  days?: number
  setDays?: (days: number) => void
}

export const LearningProgressChart = observer(({ chartData, days, setDays }: LearningProgressChartProps) => {
  const highestValue = Math.max(...chartData.map(data => data.value))
  const highestValueData = chartData.find(data => data.value === highestValue)

  return (
    <Box sx={styles.root}>
      <AppBox alignItems={'center'} gap={7} width={chartData.length > 0 ? '100%' : 'auto'}>
        <Typography sx={{ color: 'text.secondary', fontSize: 11 }}>Last</Typography>
        <AppButton sx={{ fontSize: 11, padding: '0.3em 0.7em' }} onClick={() => setDays && setDays(7)} variant={days === 7 ? 'contained' : 'text'}>7 days</AppButton>
        <AppButton sx={{ fontSize: 11, padding: '0.3em 0.7em' }} onClick={() => setDays && setDays(14)} variant={days === 14 ? 'contained' : 'text'}>14 days</AppButton>
        <AppButton sx={{ fontSize: 11, padding: '0.3em 0.7em' }} onClick={() => setDays && setDays(30)} variant={days === 30 ? 'contained' : 'text'}>30 days</AppButton>
      </AppBox>
      {chartData.length > 0 && <ResponsiveContainer width='100%' height='100%'>
        <AreaChart data={chartData || []}>
          <XAxis
            dataKey="date"
            padding={{ left: 15, right: 15 }}
            tickFormatter={date => format(new Date(date), 'd MMM')}
            fontSize={10}
          />
          {/* Tooltip */}
          <Tooltip
            formatter={(value, name) => [`${value}`, `${name}`]}
            contentStyle={{
              backgroundColor: '#161616',
              borderRadius: '12px',
              padding: '0.5em',
            }}
            itemStyle={{
              fontSize: 11,
              padding: '0.1em 0',
              margin: '0',
            }}
            labelStyle={{
              color: '#fff',
              fontSize: 11,
              fontWeight: 'bold',
              paddingBottom: '0.1em',
            }}
            active={true}
            payload={[{ name: 'value', value: highestValue }]}
            label={highestValueData?.date}
          />
          <Area type="monotone" dataKey="value" stroke="#27827A" fill="#27827A" />
          {highestValueData && (
            <ReferenceDot
              x={highestValueData.date}
              y={highestValue}
              r={5}
              fill="red"
              stroke="red"
              isFront={true}
            />
          )}
        </AreaChart>
      </ResponsiveContainer>}
      {chartData.length === 0 && <Typography>No data available</Typography>}
    </Box>
  )
})

const styles = {
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 7,
    alignItems: 'center',
    justifyContent: 'center',
  },
}
