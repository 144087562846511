import { Avatar, Popover, Theme, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { AppPaper } from 'src/component/common/analog/molecule/AppPaper'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { useState } from 'react'
import { UserDetails } from 'src/feature/dashboard/model/dashboard-model'
import { useUserStore } from 'src/feature/user/store/user.store'
import { OverallMetricsWithValue } from '../molecules/OverallMetricsWithValue'

export interface OverallMetricsProps {
  userList: UserDetails[]
  label: string
  iconName?: string
  isLoading?: boolean
}

const getInitials = (name: string) => name.split(' ').map(word => word.charAt(0))
  .join('')
  .toUpperCase()

export const OverallMetrics = observer(({ userList, label, iconName, isLoading = false }: OverallMetricsProps) => {
  const userStore = useUserStore()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  return (
    <AppPaper sx={styles.root}
      aria-owns={open ? 'mouse-over-popover' : undefined}
      aria-haspopup='true'
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <OverallMetricsWithValue
        isLoading={isLoading}
        iconName={iconName}
        size={userList.length}
        label={label}
      />
      <Popover
        id='mouse-over-popover'
        sx={{ pointerEvents: 'none' }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <AppBox sx={styles.popoverWrapper}>
          <AppBox sx={{ alignItems: 'center', gap: 10 }}>
            <Avatar sx={styles.avatar}>{getInitials(`${userStore.organisation?.name}`)}</Avatar>
            <AppBox sx={{ flexDirection: 'column', gap: 5 }}>
              <Typography variant='body2' sx={{ color: 'text.primary' }}>{userStore.organisation?.name}</Typography>
              <Typography fontSize={10} sx={{ color: 'text.secondary' }}>
                {`${userList.length || 0} members`}
              </Typography>
            </AppBox>
          </AppBox>
          <AppBox sx={{ gap: 10, display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}>
            {userList.map((user: UserDetails) => (
              <AppBox key={user.id} sx={{ alignItems: 'center', gap: 5 }}>
                <Avatar sx={{ ...styles.avatar, width: '4em', height: '4em', fontSize: 7 }}>{getInitials(`${user.firstName} ${user.lastName}`)}</Avatar>
                <Typography sx={{ fontSize: 10 }}>{`${user.firstName} ${user.lastName}`}</Typography>
              </AppBox>
            ))}
          </AppBox>
        </AppBox>
      </Popover>
    </AppPaper>
  )
})

const styles = {
  root: (theme: Theme) => ({
    gap: 7,
    padding: 15,
    borderRadius: '20px',
    cursor: 'pointer',
    width: 160,
    height: 110,
    transition: 'border-radius border-color 0.3s ease',
    '&:hover': {
      borderRadius: '0px',
      borderColor: `${theme.palette.primary.main}60`,
    },
  }),
  avatar: {
    backgroundColor: 'background.dark',
    color: 'text.primary',
    fontSize: 15,
  },
  popoverWrapper: {
    flexDirection: 'column',
    padding: 15,
    gap: 10,
  },
}
