import { observer } from 'mobx-react-lite'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { Box, Tooltip, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import WhatshotRoundedIcon from '@mui/icons-material/WhatshotRounded'
import TrendingDownIcon from '@mui/icons-material/TrendingDown'
import { useDashboardStore } from 'src/feature/dashboard/store/dashboard.store'

export type TeamStrengthWeaknessProps = {
  levelId: string
}

export const TeamStrengthWeakness = observer(({ levelId }: TeamStrengthWeaknessProps) => {
  const dashboardStore = useDashboardStore()
  const [teamStrength, setTeamStrength] = useState<string[]>([])
  const [teamWeakness, setTeamWeakness] = useState<string[]>([])

  useEffect(() => {
    const organisationChildLevels = dashboardStore.organisationLevelOverview
      .filter(level => level.parentLevel === levelId)

    const initialMetrics: { strength: { level: any, totalAverageRating: number, totalExpectedRating: number }[], weakness: { level: any, totalAverageRating: number, totalExpectedRating: number }[] } = {
      strength: [],
      weakness: [],
    }

    const updatedMetrics = organisationChildLevels.reduce((acc, level) => {
      const summary = dashboardStore.positionInsightsSummary[level.id]

      if (summary) {
        const totalExpectedRating = summary.PositionInsightChartsData.reduce((sum, data) => sum + data.expectedRating, 0)
        const totalAverageRating = summary.PositionInsightChartsData.reduce((sum, data) => sum + data.averageUserRating, 0)

        if (totalExpectedRating > 0 && totalAverageRating > 0) {
          if (totalAverageRating >= totalExpectedRating) {
            acc.strength.push({ level, totalAverageRating, totalExpectedRating })
          } else {
            acc.weakness.push({ level, totalAverageRating, totalExpectedRating })
          }
        }
      }

      return acc
    }, initialMetrics)

    updatedMetrics.strength.sort((a, b) => b.totalAverageRating - a.totalAverageRating)

    console.log('updatedMetrics', updatedMetrics)

    setTeamStrength(updatedMetrics.strength.map(item => item.level.name))
    setTeamWeakness(updatedMetrics.weakness.map(item => item.level.name))
  }, [levelId])

  return (
    <Box sx={styles.root}>
      <AppBox sx={{ flexDirection: 'row', gap: 5, alignItems: 'center', width: '100%' }}>
        <WhatshotRoundedIcon fontSize='small' sx={{ color: 'orange' }} />
        <Tooltip title={'Strength'} arrow>
          <Typography sx={{ color: 'green', width: '10em' }} noWrap>{'Strength'}</Typography>
        </Tooltip>
        <AppBox sx={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start', flexWrap: 'wrap', width: '100%' }}>
          <Tooltip title={teamStrength.join(', ') || '-'} arrow>
            <Typography sx={{ maxWidth: '15em', color: 'text.secondary' }} noWrap>
              {teamStrength.join(', ') || '-'}
            </Typography>
          </Tooltip>
        </AppBox>
      </AppBox>
      <AppBox sx={{ flexDirection: 'row', gap: 5, alignItems: 'center', width: '100%' }}>
        <TrendingDownIcon fontSize='small' color='primary' />
        <Tooltip title={'Weakness'} arrow>
          <Typography sx={{ color: 'red', width: '10em' }} noWrap>{'Weakness'}</Typography>
        </Tooltip>
        <AppBox sx={{ flexDirection: 'row', gap: 5, alignItems: 'flex-start', flexWrap: 'wrap', width: '100%' }}>
          <Tooltip title={teamWeakness.join(', ') || '-'} arrow>
            <Typography sx={{ maxWidth: '15em', color: 'text.secondary' }} noWrap>
              {teamWeakness.join(', ') || '-'}
            </Typography>
          </Tooltip>
        </AppBox>
      </AppBox>
    </Box>
  )
})

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'center',
    gap: 10,
  },
}