import { Box, Fade, Icon, Skeleton, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { If } from 'src/component/common/original/atom/If'
import { iconMapping } from 'src/util/icon-mapping'

export interface OverallMetricsWithValueProps {
  iconName?: string
  size: number
  label: string
  isLoading?: boolean
}

export const OverallMetricsWithValue = observer(({ iconName, size, label, isLoading = false }: OverallMetricsWithValueProps) => {
  return (
    <Fade in timeout={500} mountOnEnter unmountOnExit>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between', alignItems: 'start', gap: 5 }}>
        <If condition = {!isLoading}>
          <Typography sx={{ color: 'text.secondary', fontSize: 11 }}>{label}</Typography>
          <AppBox gap={3} sx={{ alignItems: 'center' }}>
            <Typography variant='h1' sx={{ color: 'text.primary' }}>{size}</Typography>
            {iconName && <Icon sx={{ color: 'secondary.main' }} component={iconMapping[iconName]} />}
          </AppBox>
        </If>
        <If condition = {isLoading}>
          <Skeleton variant='text' width={100} height={10} />
          <Skeleton variant='text' width={50} height={15} />
        </If>
      </Box>
    </Fade>
  )
})