import { Box, Theme, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { AppCarousel } from 'src/component/common/analog/molecule/carousel/AppCarousel'
import { useDashboardStore } from 'src/feature/dashboard/store/dashboard.store'
import { CourseListPresentationItem } from 'src/feature/e-learning/component/organism/CourseListPresentationItem'
import { Caption } from 'src/feature/localization/component/atom/Caption'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'

export const OverallCourseOverview = observer(() => {
  const dashboardStore = useDashboardStore()
  const { translation } = useLocalizationStore()

  const texts = translation.pages.eLearning

  useEffect(() => {
    dashboardStore.getOverallCourseOverview()
  }, [])

  return (
    <Box sx={styles.root}>
      <AppBox sx={styles.container}>
        <Typography variant={'h4'}>{'Popular courses'}</Typography>
        {dashboardStore.overallCourseOverview?.popularCourses && dashboardStore.overallCourseOverview?.popularCourses.length !== 0 && <AppCarousel showDots={true} showProgressBar={false}>
          {dashboardStore.overallCourseOverview?.popularCourses.map(c => <CourseListPresentationItem item={c} key={c.id} />)}
        </AppCarousel>}
        {dashboardStore.overallCourseOverview?.popularCourses && dashboardStore.overallCourseOverview?.popularCourses.length === 0 && <Caption sx={{ color: 'text.secondary' }} textValue={texts.missingCourse} />}
      </AppBox>
      <AppBox sx={styles.container}>
        <Typography variant={'h4'}>{'Popular Assigned'}</Typography>
        {dashboardStore.overallCourseOverview?.popularAssignedCourses && dashboardStore.overallCourseOverview?.popularAssignedCourses.length !== 0 && <AppCarousel showDots={true} showProgressBar={false}>
          {dashboardStore.overallCourseOverview?.popularAssignedCourses.map(c => <CourseListPresentationItem item={c} key={c.id} />)}
        </AppCarousel>}
        {dashboardStore.overallCourseOverview?.popularAssignedCourses && dashboardStore.overallCourseOverview?.popularAssignedCourses.length === 0 && <Caption sx={{ color: 'text.secondary' }} textValue={texts.missingCourse} />}
      </AppBox>
      <AppBox sx={styles.container}>
        <Typography variant={'h4'}>{'Popular completed'}</Typography>
        {dashboardStore.overallCourseOverview?.popularUserCompletedCourses && dashboardStore.overallCourseOverview?.popularUserCompletedCourses.length !== 0 && <AppCarousel showDots={true} showProgressBar={false}>
          {dashboardStore.overallCourseOverview?.popularUserCompletedCourses.map(c => <CourseListPresentationItem item={c} key={c.id} />)}
        </AppCarousel>}
        {dashboardStore.overallCourseOverview?.popularUserCompletedCourses && dashboardStore.overallCourseOverview?.popularUserCompletedCourses.length === 0 && <Caption sx={{ color: 'text.secondary' }} textValue={texts.missingCourse} />}
      </AppBox>
      <AppBox sx={styles.container}>
        <Typography variant={'h4'}>{'Popular wishlisted'}</Typography>
        {dashboardStore.overallCourseOverview?.popularUserWishlistedCourses && dashboardStore.overallCourseOverview?.popularUserWishlistedCourses.length !== 0 && <AppCarousel showDots={true} showProgressBar={false}>
          {dashboardStore.overallCourseOverview?.popularUserWishlistedCourses.map(c => <CourseListPresentationItem item={c} key={c.id} />)}
        </AppCarousel>}
        {dashboardStore.overallCourseOverview?.popularUserWishlistedCourses && dashboardStore.overallCourseOverview?.popularUserWishlistedCourses.length === 0 && <Caption sx={{ color: 'text.secondary' }} textValue={texts.missingCourse} />}
      </AppBox>
      <AppBox sx={styles.container}>
        <Typography variant={'h4'}>{'Popular categories'}</Typography>
        {dashboardStore.overallCourseOverview?.popularCategories && dashboardStore.overallCourseOverview?.popularCategories.length !== 0 && dashboardStore.overallCourseOverview?.popularCategories.map(c => <Caption key={c} sx={{ ...styles.pill, marginLeft: '0px' }} textValue={c} />)}
        {dashboardStore.overallCourseOverview?.popularCategories && dashboardStore.overallCourseOverview?.popularCategories.length === 0 && <Caption sx={{ color: 'text.secondary' }} textValue={texts.missingCourse} />}
      </AppBox>
    </Box>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 7,
  },
  container: (theme: Theme) => ({
    flexDirection: 'column',
    overflow: 'hidden',
    borderRadius: '20px',
    border: `1px solid ${theme.palette.alternate.background}40`,
    padding: '0.7rem 0.7rem 0.4rem 0.7rem',
    gap: 7,
  }),
  pill: {
    marginLeft: '10px',
    borderRadius: '1rem',
    border: '1px solid',
    borderColor: 'text.primary',
    padding: '0px 12px',
    backgroundColor: 'background.default',
    color: 'text.primary',
  },
}
