import { useBreakpoints } from '../../../../theme/hook/useBreakpoints'
import { ProfileLink } from '../organism/ProfileLink'
import { observer } from 'mobx-react-lite'
import { DesktopHeader } from '../template/DesktopHeader'
import { MobileHeader } from '../template/MobileHeader'
import { useLocation } from 'react-router-dom'
import { ThemeButton } from 'src/feature/theme/component/provider/ThemeButton'
import { LocalesButton } from 'src/feature/localization/component/molecule/LocalesButton'
import { NotificationLink } from '../organism/NotificationLink'

export const WithSubscriptionHeader = observer(() => {
  const { isMd } = useBreakpoints()
  const { pathname } = useLocation()

  return (
    <>
      {isMd ? (
        <MobileHeader pathname={pathname} >
          {/* <MainLinks /> */}
          <ProfileLink />
          {/* <NotificationLink /> */}
          <ThemeButton />
          {/* <TourLink /> */}
          <LocalesButton />
        </MobileHeader>
      ) : (
        <DesktopHeader listProps={{ spacing: 26 }}>
          {/* <MainLinks /> */}
          <NotificationLink />
          <ProfileLink />
          {/* <ThemeButton /> */}
          {/* <TourLink /> */}
          {/* <LocalesButton /> */}
        </DesktopHeader>
      )}
    </>
  )
})
