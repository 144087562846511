import { observer } from 'mobx-react-lite'
import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDashboardStore } from 'src/feature/dashboard/store/dashboard.store'
import { Word } from 'src/feature/localization/component/atom/Word'

export type TeamInsightMetricsProps = {
  levelId: string
}

export const TeamInsightMetrics = observer(({ levelId }: TeamInsightMetricsProps) => {
  const dashboardStore = useDashboardStore()
  const [teamInsightMetrics, setTeamInsightMetrics] = useState<any>({
    userNotRatedSkills: 0,
    userMatchedSkills: 0,
    userNotMatchedSkills: 0,
  })

  useEffect(() => {
    const organisationChildLevelsIds = dashboardStore.organisationLevelOverview
      .filter(level => level.parentLevel === levelId)
      .map(level => level.id)
    
    const initialMetrics = {
      userNotRatedSkills: 0,
      userMatchedSkills: 0,
      userNotMatchedSkills: 0,
    }
    
    const updatedMetrics = organisationChildLevelsIds.reduce((acc, levelId) => {
      const summary = dashboardStore.positionInsightsSummary[levelId]
      
      if (summary) {
        acc.userNotRatedSkills += summary.userNotRatedSkills || 0
        acc.userMatchedSkills += summary.userMatchedSkills || 0
        acc.userNotMatchedSkills += summary.userNotMatchedSkills || 0
      }
      
      return acc
    }, initialMetrics)
    
    setTeamInsightMetrics(updatedMetrics)
  }, [levelId])

  return (
    <Box sx={styles.root}>
      <Typography sx={styles.textWrapper}>
        {teamInsightMetrics.userNotRatedSkills || 0}
        <Word sx={{ color: 'text.secondary' }}>{' Not completed'}</Word>
      </Typography>
      <Typography sx={styles.textWrapper}>
        {teamInsightMetrics.userMatchedSkills || 0}
        <Word sx={{ color: 'text.secondary' }}>{' Meet expectation'}</Word>
      </Typography>
      <Typography sx={styles.textWrapper}>
        {teamInsightMetrics.userNotMatchedSkills || 0}
        <Word sx={{ color: 'text.secondary' }}>{' Competence gap'}</Word>
      </Typography>
    </Box>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'center',
    gap: 10,
  },
  textWrapper: {
    display: 'flex',
    alignItems: 'baseline',
    fontSize: 15,
    gap: 5,
  },
}