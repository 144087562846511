import { AppAvatar } from '../../../../../component/common/analog/molecule/AppAvatar'
import { If } from '../../../../../component/common/original/atom/If'
import { TranslatableText } from '../../../../localization/component/atom/TranslatableText'
import { observer } from 'mobx-react-lite'
import { useLocalizationStore } from '../../../../localization/store/localization.store'
import { useUserStore } from '../../../../user/store/user.store'
import { LINK_LABEL_CLASS_NAME, NavListItem } from '../../../../../component/common/original/molecule/NavListItem'
import { SxHelperStyle } from '../../../../theme/style/sx-helper.style'
import Box from '@mui/material/Box'
import React, { useEffect, useRef, useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Collapse, Menu, Typography } from '@mui/material'
import { ProfileMenu } from 'src/feature/profile/component/organism/ProfileMenu'
import { useBreakpoints } from 'src/feature/theme/hook/useBreakpoints'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'

export const ProfileLink = observer(() => {
  const { translation } = useLocalizationStore()
  const { userData, avatar } = useUserStore()
  const [plan, setPlan] = useState('transparent')
  const [showMenu, setShowMenu] = useState(false)
  const menuItemRef = useRef(null)
  const { isMd } = useBreakpoints()

  useEffect(() => {
    if (!userData?.verified) {
      setPlan('#ffca28 ')
    }
  }, [userData?.verified])

  const handleOpen = () => {
    setShowMenu(!showMenu)
  }
  const handleClose = () => {
    setShowMenu(false)
  }

  return (
    <React.Fragment>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} color={'#22222266'} ref={menuItemRef}>
        <NavListItem className="second-step" onClick={handleOpen} isMain={true} sx={styles.root}>
          <Box width={'100%'} bgcolor={plan} display={'flex'} justifyContent={'center'} alignItems={'center'} borderRadius={'50%'}>
            <AppAvatar
              src={avatar}
              alt={translation.util.avatar}
              outerProps={{
                squareSize: '2em',
              }}
            />
          </Box>
          <AppBox display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'center'} ml={5}>
            <If condition={userData?.firstName}><TranslatableText textValue={userData?.firstName} className={LINK_LABEL_CLASS_NAME} style={{ fontSize: 14 }} /></If>
            {showMenu ? <KeyboardArrowUpIcon fontSize='small' sx={{ color: 'text.primary' }} /> : <KeyboardArrowDownIcon fontSize='small' sx={{ color: 'text.primary' }} />}
          </AppBox>
        </NavListItem>
        { isMd ? <Collapse in={showMenu}><ProfileMenu /></Collapse> : <Menu
          anchorEl={menuItemRef.current}
          id="account-menu"
          open={showMenu}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              minWidth: 150,
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 7,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                backgroundColor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <AppBox display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'center'} mb={10}>
            <Typography sx={{ ml: 1, width: 90, fontSize: 14 }} noWrap>{userData?.firstName || 'NA'}</Typography>
            <Typography variant={'caption'} sx={{ ml: 1, width: 100, fontSize: 8 }} noWrap>{userData?.profession || 'No role assigned'}</Typography>
          </AppBox>
          <ProfileMenu />
        </Menu>
        }
      </Box>
    </React.Fragment>
  )
})

const styles = {
  root: {
    ...SxHelperStyle.flexCenter,
    flexDirection: 'row',
  },
}
