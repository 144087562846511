import React, { useMemo } from 'react'
import { Box, Skeleton } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { generateUniqId } from 'src/model/Id'

export type RectangleDefinition = {
  width: string | number
  height: string | number
  borderRadius?: string | number
  color?: string
}

export type AppSkeletonProps = {
  count?: number
  direction?: 'row' | 'column'
  rects: RectangleDefinition[]
}

export const AppSkeleton = observer(({ count = 0, direction = 'row', rects }: AppSkeletonProps) => {
  const skeletonIds = useMemo(() => Array.from({ length: count }, () => generateUniqId()), [count])
  const rectIds = useMemo(() => rects.map(() => generateUniqId()), [rects])

  return (
    <Box display="flex" flexDirection={direction} gap={10} flexWrap="wrap">
      {skeletonIds.map(skeletonId => (
        <Box key={skeletonId} display="flex" flexDirection="column" gap={5}>
          {rects.map((rect, index) => (
            <Skeleton
              key={rectIds[index]}
              variant="rectangular"
              width={rect.width}
              height={rect.height}
              sx={{
                borderRadius: rect.borderRadius,
                bgcolor: rect.color,
              }}
            />
          ))}
        </Box>
      ))}
    </Box>
  )
})