import { appRoutePath } from '../../../router/util/app-route-path'
import { useLocalizationStore } from '../../../localization/store/localization.store'
import { observer } from 'mobx-react-lite'
import { NavModel } from '../../../../model/Nav'
import { mergeTranslationWithObjects } from '../../../localization/util/localization.helper'
//import { useBreakpoints } from '../../../theme/hook/useBreakpoints'
import { useMainStore } from '../../../../store/main.store'
import { DesktopSidebar } from 'src/feature/sidebar/component/DesktopSidebar'

export const ProfileMenu = observer(() => {
  const { translation } = useLocalizationStore()
  const mainStore = useMainStore()

  const { profileSidebar } = translation.components

  const navLinks: NavModel<'props.link'> = {
    profileInfo: {
      props: {
        link: appRoutePath.profileInfo,
        hidden: false,
        icon: 'DashboardCustomizeRoundedIcon',
      },
    },
    editProfile: {
      props: {
        link: appRoutePath.editProfile,
        hidden: false,
        icon: 'DriveFileRenameOutlineRoundedIcon',
      },
    },
    editPositions: {
      props: {
        link: appRoutePath.editPositions,
        hidden: true,
      },
    },
    subscription: {
      props: {
        link: appRoutePath.subscription,
        hidden: true,
      },
    },
    paymentHistory: {
      props: {
        link: appRoutePath.paymentHistory,
        hidden: true,
      },
    },
    // subscription: { props: { link: appRoutePath.subscription, hidden: userStore.hasPrepaidLearning() } },
    // paymentHistory: { props: { link: appRoutePath.paymentHistory, hidden: userStore.hasPrepaidLearning() } },
  }

  const desktopNav = mergeTranslationWithObjects(profileSidebar.nav, navLinks, {
    divider: {
      isDivider: true,
      props: {
        component: 'li',
      },
    },
    signOut: {
      props: {
        onClick: mainStore.handleSignOutApp,
        kind: 'buttonLikeLink',
        icon: 'LogoutRoundedIcon',
      },
    },
  }) as unknown as NavModel

  //const { divider, signOut, ...mobileNav } = desktopNav
  //const currentNav = isMd ? mobileNav : desktopNav

  return (
    <DesktopSidebar
      nav={desktopNav}
      baseSidebarProps={{ showDivider: false, extraSpace: true }}
    />
  )
})