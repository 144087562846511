import { observer } from 'mobx-react-lite'
import { ELearningCourseCategory } from '../../model/ELearningCourseCategory'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { useNavigate } from 'react-router-dom'
import { appRoutePath } from 'src/feature/router/util/app-route-path'
import { Chip, Fade } from '@mui/material'

export type CategoryItemProp = {
  item: ELearningCourseCategory
  index: number
}

export const CategoryItem = observer(({ item, index }: CategoryItemProp) => {
  const { translation } = useLocalizationStore()
  const navigate = useNavigate()
  const { categories } = translation.pages.eLearning
  type ObjectKey = keyof typeof categories
  const texts = categories[item.titleLocale as ObjectKey]

  return (
    <Fade in={true} timeout={index * 100} mountOnEnter unmountOnExit>
      <Chip
        key={index}
        variant='outlined'
        sx={styles.chip}
        label={texts.title()}
        onClick={() => navigate(appRoutePath.learningCategory(item.titleLocale))}
      />
    </Fade>
  )
})

const styles = {
  chip: {
    padding: '0.5rem 0.75rem',
    backgroundColor: 'background.paper',
    border: 'none',
    ':active': {
      boxShadow: 'unset !important',
    },
    fontSize: 12,
  },
}
