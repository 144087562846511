import { Palette } from '../../../model/Palette'

export const lightModePalette = {
  line: Palette.GOSHAWK_GREY,
  get divider() {
    return this.line
  },
  primary: {
    main: Palette.DEEP_SEA_BLUE,
    lighter: Palette.VIOLET_HEAVEN_ALPHA,
    light: Palette.VIOLET_HEAVEN,
    dark: Palette.WASH_ME,
  },
  secondary: {
    main: Palette.CORAL_REEF_GREEN,
  },
  button: {
    main: Palette.DEEP_SEA_BLUE,
    secondary: Palette.WASH_ME,
    dark: Palette.BLACK_OUT,
    alternate: Palette.WASH_ME,
  },
  error: {
    main: Palette.DANGER,
  },
  background: {
    default: Palette.WHITE,
    grey: Palette.CHEFS_HAT,
    paper: Palette.WHITE,
    alternate: Palette.HAN_BLUE_LIGHT,
    light: Palette.MIST_WHISPER,
    secondary: Palette.BLACK_OUT,
    dark: Palette.WASH_ME,
  },
  text: {
    primary: Palette.BLACK,
    secondary: Palette.SMOKY_SLATE,
    dark: Palette.BLACK_OUT,
    alternate: Palette.GOSHAWK_GREY_ALPHA_50,
    light: Palette.BRIGHT_GREY_2,
  },
  grey: {
    0: Palette.BRIGHT_GREY,
    0.5: Palette.BRIGHT_GREY_2,
    1: Palette.GOSHAWK_GREY_ALPHA_70,
    2: Palette.GOSHAWK_GREY_ALPHA_60,
    3: Palette.GOSHAWK_GREY_ALPHA_50,
    4: Palette.GOSHAWK_GREY_ALPHA_40,
    5: Palette.GOSHAWK_GREY,
    6: Palette.BLACK_OUT_ALPHA_50,
    7: Palette.BLACK_OUT_ALPHA_40,
    8: Palette.BLACK_OUT,
  },
  alternate: {
    primary: Palette.DEEP_SEA_BLUE,
    secondary: Palette.BRIGHT_GREY_2,
    alternate: `${Palette.DEEP_SEA_BLUE}69`,
    background: Palette.DEEP_SEA_BLUE,
    text: Palette.WASH_ME,
    dark: Palette.DEEP_SEA_BLUE,
    light: Palette.WASH_ME,
    lighter: Palette.WASH_ME,
  },
}
