import { Box, Fade, Tooltip, Typography, useTheme, Zoom } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Palette } from 'src/feature/theme/model/Palette'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { AppUnstyledButton } from './button/AppUnstyledButton'
import CloseIcon from '@mui/icons-material/Close'
import StarRoundedIcon from '@mui/icons-material/StarRounded'
import { AppBox } from './AppBox'
import { AppIconButton } from './button/AppIconButton'
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded'

export type CircularRatingProps = {
  size?: number
  totalSegments?: number
  strokeWidth?: number
  paddingAngle?: number
  indexKey: number
  userRating?: number
  expectedRating?: number
  label: string
  showLevelBtn?: boolean
  onRateSkillClick?: (index: number) => void
  showRemoveBtn?: boolean
  onRemoveSkillClick?: () => void
  showRating?: boolean
  viewLabel?: string
  defaultColor?: string
}

export const AppCircularRating = observer(({
  size = 180,
  strokeWidth = 15,
  paddingAngle = 17,
  totalSegments = 5,
  indexKey,
  userRating,
  expectedRating,
  label,
  showLevelBtn = false,
  onRateSkillClick,
  showRemoveBtn = false,
  onRemoveSkillClick,
  showRating = false,
  viewLabel,
  defaultColor,
}: CircularRatingProps) => {
  const theme = useTheme()
  const { translation } = useLocalizationStore()
  const translations = translation.pages.myTeam.rolesButton

  const radius = size / 2 - strokeWidth / 2
  const segmentAngle = 360 / totalSegments
  const gapAngle = paddingAngle
  const arcAngle = segmentAngle - gapAngle
  const center = size / 2

  const polarToCartesian = (cx: number, cy: number, radius: number, angle: number) => {
    const rad = (angle * Math.PI) / 180
    
    return {
      x: cx + radius * Math.cos(rad),
      y: cy + radius * Math.sin(rad),
    }
  }

  const describeArc = (x: number, y: number, radius: number, startAngle: number, endAngle: number) => {
    const start = polarToCartesian(x, y, radius, endAngle)
    const end = polarToCartesian(x, y, radius, startAngle)

    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1'

    return [
      `M ${start.x} ${start.y}`,
      `A ${radius} ${radius} 0 ${largeArcFlag} 0 ${end.x} ${end.y}`,
    ].join(' ')
  }
  
  const getSegmentColor = (segmentIndex: number) => {
    if (viewLabel === 'UserView' || viewLabel === 'ManagerUserView') {
      if (showRating && userRating && (segmentIndex < userRating)) {
        return `${theme.palette.alternate.primary}`
      } else if (showRating && expectedRating && (segmentIndex < expectedRating)) {
        return `${theme.palette.alternate.primary}69`
      }
    }

    if (viewLabel === 'UserAssessmentView') {
      if (userRating && (segmentIndex < userRating)) {
        return `${theme.palette.alternate.primary}`
      }
    }

    if (viewLabel === 'ManagerView') {
      if (expectedRating && (segmentIndex < expectedRating)) {
        return `${theme.palette.alternate.primary}`
      }
    }
  }

  return (
    <Box
      sx={{
        ...styles.ringWrapper,
        position: 'relative',
        width: size,
        height: size,
        display: 'inline-block',
      }}
    >
      <Zoom in={true} timeout={700} mountOnEnter unmountOnExit>
        <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
          {/* Segments */}
          {[...Array(totalSegments)].map((_, index) => {
            const startAngle = index * segmentAngle - 90
            const endAngle = startAngle + arcAngle
            const segmentColor = getSegmentColor(index) || (defaultColor || theme.palette.background.default)

            return (
              <path
                key={`segment-${startAngle}`}
                d={describeArc(center, center, radius, startAngle, endAngle)}
                fill="none"
                stroke={segmentColor}
                strokeWidth={strokeWidth}
                strokeLinecap="round"
              />
            )
          })}
        </svg>
      </Zoom>
      {/* Centered Content */}
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 5,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Fade in={showRating} timeout={800} mountOnEnter unmountOnExit><Typography sx={styles.ratingWrapper}>{userRating || 0}/{expectedRating || 0}</Typography></Fade>
        <Tooltip title={label} placement='top' arrow><Typography sx={{ textAlign: 'center', width: size - 50, fontSize: 12 }} noWrap>{label}</Typography></Tooltip>
        <AppBox alignItems='center' justifyContent='center' gap={1}>
          {(userRating !== null && expectedRating !== 0) && ((userRating ?? 0) > (expectedRating ?? 0)) && <Fade in={showRating} timeout={800} mountOnEnter unmountOnExit><StarRoundedIcon fontSize='medium' style={{ color: 'orange' }} /></Fade>}
          {!showRemoveBtn && showLevelBtn && <AppIconButton size='small' sx={styles.buttonContainer} onClick={() => onRateSkillClick && onRateSkillClick(indexKey)}>{
            <Tooltip title={(userRating ? userRating === 0 : expectedRating === 0) ? translations.rateSkill() : translations.updateSkill()}><LaunchRoundedIcon fontSize='small' /></Tooltip>
          }</AppIconButton>}
        </AppBox>
        {showRemoveBtn && showLevelBtn && <AppUnstyledButton sx={styles.closeButton} type={'button'} onClick={onRemoveSkillClick}>
          <Tooltip title={translation.pages.editPositions.removeButton()}><CloseIcon fontSize='medium' color='inherit' /></Tooltip>
        </AppUnstyledButton>}
      </Box>
    </Box>
  )
})

const styles = {
  ringWrapper: {
    transition: 'cursor 0.3s',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  ratingWrapper: {
    backgroundColor: 'alternate.primary',
    color: 'alternate.lighter',
    padding: '3px 6px',
    borderRadius: '6px',
    fontSize: '0.8em',
  },
  buttonContainer: {
    color: 'text.primary',
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'translateX(2px) translateY(-2px)',
    },
  },
  closeButton: {
    border: '2px solid',
    borderColor: `${Palette.DANGER}30`,
    borderRadius: '50%',
    backgroundColor: `${Palette.DANGER}30`,
    transition: 'transform 0.3s ease, borderColor 0.3s ease',
    '&:hover': {
      transform: 'scale(1.05)',
      borderColor: Palette.DANGER,
    },
    color: Palette.DANGER,
    width: '1.5rem',
    height: '1.5rem',
  },
}
