import { observer } from 'mobx-react-lite'
import Confetti from 'react-confetti'
import { useEffect, useState } from 'react'

export type AppConfettiProps = {
  show: boolean
}

export const AppConfetti = observer((props: AppConfettiProps) => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    xOffset: window.scrollX,
    yOffset: window.scrollY,
  })

  useEffect(() => {
    const updateDimensions = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
        xOffset: window.scrollX,
        yOffset: window.scrollY,
      })
    }

    window.addEventListener('resize', updateDimensions)
    window.addEventListener('scroll', updateDimensions)
    
    return () => {
      window.removeEventListener('resize', updateDimensions)
      window.removeEventListener('scroll', updateDimensions)
    }
  }, [])

  return (
    <Confetti
      width={dimensions.width}
      height={dimensions.height}
      numberOfPieces={300}
      gravity={0.05}
      friction={0.99}
      initialVelocityX={0.5}
      initialVelocityY={0.5}
      recycle={false}
      run={props.show}
      tweenDuration={5000}
      confettiSource={{ x: 0, y: 0, w: dimensions.width, h: 500 }}
      colors={['#27827A', '#FFD700', '#FF4500', '#FF69B4', '#00BFFF']}
      style={{
        position: 'fixed',
        top: dimensions.yOffset,
        left: dimensions.xOffset,
      }}
    />
  )
})
