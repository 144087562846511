import { observer } from 'mobx-react-lite'
import { useDashboardStore } from '../store/dashboard.store'
import { Box, Chip, Icon, Typography } from '@mui/material'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { useState } from 'react'
import { UserCards } from './UserCards'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { iconMapping } from 'src/util/icon-mapping'
import { useNavigate } from 'react-router-dom'
import { appRoutePath } from 'src/feature/router/util/app-route-path'
import { useAuthStore } from 'src/feature/auth/store/auth.store'
import { OrganisationRole } from 'src/feature/control-panel/model/OrganisationRole'

export type UserListProps = {
  levelId: string
}

export const UserList = observer(({ levelId }: UserListProps) => {
  const { translation } = useLocalizationStore()
  const translations = translation.pages.myTeam
  const dashboardStore = useDashboardStore()
  const navigate = useNavigate()
  const [selectedSort, setSelectedSort] = useState<string>('')
  const authStore = useAuthStore()

  const setSelectedFilter = (label: string) => {
    if (selectedSort === label) {
      setSelectedSort('')
    } else {
      setSelectedSort(label)
      switch (label) {
      case translations.sortHeader.activeUsers():
        dashboardStore.teamOverview?.[levelId]?.userIds.sort((a: string, b: string) => {
          return dashboardStore.userOverview?.[b]?.CompletedCourses - dashboardStore.userOverview?.[a]?.CompletedCourses
        })
        break
      case translations.sortHeader.inactiveUsers():
        dashboardStore.teamOverview?.[levelId]?.userIds.sort((a: string, b: string) => {
          return dashboardStore.userOverview?.[a]?.CompletedCourses - dashboardStore.userOverview?.[b]?.CompletedCourses
        })
        break
      default:
        break
      }
    }
  }
  
  return (
    <Box sx={styles.cardWrapper}>
      {(dashboardStore.teamOverview?.[levelId]?.userIds.length ?? 0) === 0 && <AppBox sx={{ flexDirection: 'column', alignItems: 'center', color: 'text.secondary', gap: 5 }}>
        {authStore.hasRole(OrganisationRole.SystemAdministrator) && <>
          <Typography sx={{ textAlign: 'center' }}>{translations.noUsersMsg()}</Typography>
          <Typography sx={{ fontWeight: 500, cursor: 'pointer', color: 'secondary.main', textAlign: 'center' }} onClick={() => navigate(appRoutePath.controlpanelUsers)}>{translation.pages.myTeam.redirectionMessage()}</Typography>
        </>}
        {!authStore.hasRole(OrganisationRole.SystemAdministrator) && <>
          <Typography>{translations.managerNoUsersMsg()}</Typography>
        </>}
      </AppBox>}
      {(dashboardStore.teamOverview?.[levelId]?.userIds.length ?? 0) > 0 && <>
        <AppBox sx={styles.chipWrapper}>
          <Typography sx={{ fontSize: '1.1em' }}>{translations.sortHeader.sortBy()}</Typography>
          <Box sx={styles.chipContainer}>
            {[{ title: translations.sortHeader.activeUsers(), icon: 'HowToRegRoundedIcon' }, { title: translations.sortHeader.inactiveUsers(), icon: 'PersonOffRoundedIcon' }].map(label => (
              <Chip
                key={label.title}
                variant='outlined'
                icon={<Icon sx={{ color: 'secondary.main', height: '18px', width: '18px' }} color='inherit' component={iconMapping[label.icon]} />}
                sx={{ ...styles.chip, ...(selectedSort !== label.title ? '' : { borderColor: 'secondary.main' }) }}
                label={label.title}
                onClick={() => setSelectedFilter(label.title)}
              />
            ))}
          </Box>
        </AppBox>
        <AppBox sx={styles.cardContainer}>
          {(dashboardStore.teamOverview?.[levelId]?.userIds.length ?? 0) > 0 && dashboardStore.teamOverview?.[levelId]?.userIds?.map((userId: string) => (
            <UserCards key={userId} levelId={levelId} userId={userId} />
          ))}
        </AppBox>
      </>}
    </Box>
  )
})

const styles = {
  cardWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '2rem',
  },
  chipWrapper: {
    width: '100%',
    padding: { xs: '0 1rem', md: '0 4rem' },
    flexDirection: { xs: 'column', md: 'row' },
    gap: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  chipContainer: {
    display: 'flex',
    gap: 10,
    justifyContent: { xs: 'flex-start', md: 'flex-end' },
  },
  chip: {
    padding: '0.5rem 0.75rem',
    transition: 'transform 0.3s',
    '&:hover': {
      backgroundColor: 'unset !important',
      borderColor: 'secondary.main',
      transform: 'scale(1.03)',
    },
    ':active': {
      boxShadow: 'unset !important',
    },
  },
  cardContainer: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 13,
    justifyContent: 'center',
  },
}
