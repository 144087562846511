import { observer } from 'mobx-react-lite'
import { AppPaper } from 'src/component/common/analog/molecule/AppPaper'
import { Theme } from '@mui/material/styles'
import { Box, Fade, Tooltip, Typography } from '@mui/material'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { useDashboardStore } from 'src/feature/dashboard/store/dashboard.store'
import { PositionGapInsights } from '../molecules/PositionGapInsights'
import { PositionStrengthWeakness } from '../molecules/PositionStrengthWeakness'

export const PositionInsightCards = observer(() => {
  const dashboardStore = useDashboardStore()

  return (
    <Box sx={styles.root}>
      {dashboardStore.organisationLevelOverview?.map(level => level.levelType === 'role' && (
        <Fade in={true} timeout={500} mountOnEnter unmountOnExit key={level.id}>
          <AppPaper sx={styles.cardContainer}>
            <Tooltip title={level.name} arrow>
              <Typography style={{ fontSize: '1rem', fontWeight: 500, maxWidth: '15rem' }} noWrap>{level.name}</Typography>
            </Tooltip>
            <AppBox sx={{ flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', gap: 20 }}>
              <PositionGapInsights levelId={level.id} />
              <PositionStrengthWeakness levelId={level.id} />
            </AppBox>
          </AppPaper>
        </Fade>
      ))}
    </Box>
  )
})

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 13,
    justifyContent: 'center',
  },
  cardContainer: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 15,
    padding: '1em',
    borderRadius: '12px',
    transition: 'border-color 0.3s ease',
    '&:hover': {
      borderColor: `${theme.palette.primary.main}60`,
    },
    maxWidth: '35em',
    minWidth: '35em',
  }),
}
