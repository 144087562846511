import { observer } from 'mobx-react-lite'
import { AppPaper } from 'src/component/common/analog/molecule/AppPaper'
import { Theme } from '@mui/material/styles'
import { Avatar, AvatarGroup, Box, Fade, Tooltip, Typography } from '@mui/material'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { useDashboardStore } from 'src/feature/dashboard/store/dashboard.store'
import { TeamInsightsChart } from '../molecules/TeamInsightsChart'
import { TeamInsightMetrics } from '../molecules/TeamInsightMetrics'
import { TeamStrengthWeakness } from '../molecules/TeamStrengthWeakness'
import { OrganisationLevelOverview, UserDetails } from 'src/feature/dashboard/model/dashboard-model'

const getInitials = (name: string) => name.split(' ').map(word => word.charAt(0))
  .join('')
  .toUpperCase()

export const TeamInsightCards = observer(() => {
  const dashboardStore = useDashboardStore()

  return (
    <Box sx={styles.root}>
      {dashboardStore.organisationLevelOverview?.map((level: OrganisationLevelOverview) => level.levelType === 'team' && (
        <Fade in timeout={500} mountOnEnter unmountOnExit key={level.id}>
          <AppPaper sx={styles.cardContainer}>
            <AppBox sx={styles.flexContainer}>
              <Tooltip title={level.name} arrow>
                <Typography sx={styles.levelName} noWrap>{level.name}</Typography>
              </Tooltip>
              <AvatarGroup max={3} spacing="small" slotProps={{ additionalAvatar: { sx: styles.avatar } }}>
                {level.users?.map((user: UserDetails) => (
                  <Avatar key={user.id} sx={styles.avatar}>{getInitials(`${user.firstName} ${user.lastName}`)}</Avatar>
                ))}
              </AvatarGroup>
            </AppBox>
            <AppBox sx={styles.flexContainer}>
              <TeamInsightMetrics levelId={level.id} />
              <TeamInsightsChart levelId={level.id} />
            </AppBox>
            <TeamStrengthWeakness levelId={level.id} />
          </AppPaper>
        </Fade>
      ))}
    </Box>
  )
})

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 13,
    justifyContent: 'center',
  },
  cardContainer: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 5,
    padding: '1em 1.5em',
    borderRadius: '12px',
    transition: 'border-color 0.3s ease',
    '&:hover': {
      borderColor: `${theme.palette.primary.main}60`,
    },
    maxWidth: '30em',
    minWidth: '30em',
  }),
  avatar: {
    backgroundColor: 'background.dark',
    color: 'text.primary',
    fontSize: 12,
  },
  levelName: {
    fontSize: '1rem',
    fontWeight: 500,
    maxWidth: '15rem',
  },
  flexContainer: {
    width: '100%',
    flexDirection: { xs: 'column', md: 'row' },
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 10,
  },
}
