import { AppBox } from '../../../../component/common/analog/molecule/AppBox'
import { observer } from 'mobx-react-lite'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { CoursePlanItemProgress } from '../../model/ELearningCoursePlanItem'
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech'
import { circularProgressClasses, LinearProgress, Tooltip, Typography } from '@mui/material'

export type CourseProgressRowProp = {
  progress?: CoursePlanItemProgress
}

export const CourseProgressRow = observer(({ progress }: CourseProgressRowProp) => {
  const { translation } = useLocalizationStore()
  const texts = translation.pages.eLearning

  return (
    <AppBox sx={styles.row}>
      <AppBox sx={{ alignItems: 'center', gap: 10 }}>
        <LinearProgress
          variant="determinate"
          color='inherit'
          value={progress?.percentage || 0}
          sx={{
            width: 130,
            height: 7,
            animationDuration: '600ms',
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round',
            },
            color: 'text.alternate',
            '& .MuiLinearProgress-bar': {
              color: 'secondary.main',
            },
          }}
        />
        <Typography sx={{ fontSize: 14, fontWeight: 500 }}>{progress?.percentage || 0}%</Typography>
      </AppBox>
      <Tooltip title={(progress?.testPassed ? texts.testPassed() : texts.testNotPassed())} arrow><MilitaryTechIcon fontSize='small' sx={{ color: (progress?.testPassed ? 'secondary.main' : 'text.alternate') }} /></Tooltip>
    </AppBox>
  )
})

const styles = {
  row: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 3,
  },
}
