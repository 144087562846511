import { Box, Pagination, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useDashboardStore } from '../store/dashboard.store'
import { SkillGapCards } from './SkillGapCards'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { Palette } from 'src/feature/theme/model/Palette'
import { useEffect, useState } from 'react'
import { AppPaper } from 'src/component/common/analog/molecule/AppPaper'
import { Word } from 'src/feature/localization/component/atom/Word'

export const SkillGapList = observer(() => {
  const dashboardStore = useDashboardStore()

  const [showGap, setShowGap] = useState<boolean>(false)
  const [pagelength, setPageLength] = useState<number>(0)
  const pageSize = 10
  
  useEffect(() => {
    const fetchData = async () => {
      await dashboardStore.getAllSkills(pagelength, pageSize).then(() => {
        setShowGap(true)
      })
    }
    fetchData()
  }, [])

  return (
    <AppPaper sx={{ padding: '1rem', borderRadius: '12px', gap: 20 }}>
      <AppBox sx={{ width: '100%', flexDirection: { xs: 'column', md: 'row' }, justifyContent: { md: 'space-between' }, alignItems: 'center' }} gap={15}>
        <AppBox sx={{ gap: 10, alignItems: 'center' }}>
          <Typography variant='h4'>UNUSED SKILLS</Typography>
          <Word sx={styles.pill}>{dashboardStore.allSkills?.count || '0'}</Word>
        </AppBox>
        {dashboardStore.allSkills?.count !== 0 && <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 15 }}>
          <AppBox sx={{ gap: 5, alignItems: 'center', justifyContent: 'center' }}>
            <Typography fontSize={12}>Exceed expectation</Typography>
            <AppBox sx={{ ...styles.box, backgroundColor: 'green' }} />
          </AppBox>
          <AppBox sx={{ gap: 5, alignItems: 'center', justifyContent: 'center' }}>
            <Typography fontSize={12}>Meet expectation</Typography>
            <AppBox sx={{ ...styles.box, backgroundColor: 'orange' }} />
          </AppBox>
          <AppBox sx={{ gap: 5, alignItems: 'center', justifyContent: 'center' }}>
            <Typography fontSize={12}>Competence gap</Typography>
            <AppBox sx={{ ...styles.box, backgroundColor: Palette.DANGER }} />
          </AppBox>
        </Box>}
      </AppBox>
      <AppBox alignItems='center' width='100%'>
        <Pagination
          shape='rounded'
          color='primary'
          count={Math.ceil((dashboardStore.allSkills?.count || 0) / pageSize)}
          showFirstButton
          showLastButton
          onChange={(_, page) => {
            const newPageLength = (page - 1) * pageSize
            setPageLength(newPageLength)
            dashboardStore.getAllSkills(newPageLength, pageSize)
          }}
          sx={{ '& .MuiPaginationItem-root': { fontSize: 13, height: 23 } }}
        />
      </AppBox>
      <AppBox sx={{ width: '100%', flexDirection: 'column', gap: 10 }}>
        {showGap && dashboardStore.allSkills?.count !== 0 && dashboardStore.allSkills?.skillSets.map(skill => (
          <SkillGapCards key={skill.id} skillSet={skill} />
        ))}
      </AppBox>
    </AppPaper>
  )
})

const styles = {
  box: {
    height: 15,
    width: 15,
    borderRadius: '50%',
  },
  pill: {
    borderRadius: '10px',
    border: '1px solid',
    borderColor: 'text.primary',
    padding: '0 10px',
    color: 'text.primary',
    textAlign: 'center',
    fontSize: 12,
  },
}