import { AppStyle } from '../../../../style/app.style'
import { OverrideFunc } from '../../../../model/Override'
import { Interpolation } from '@emotion/react'
import { Theme } from '@mui/material/styles'
import { Palette } from 'src/feature/theme/model/Palette'

export const MuiButton: OverrideFunc<'MuiButton'> = theme => ({
  defaultProps: {
    disableElevation: true,
    variant: 'outlined',
  },
  styleOverrides: {
    root: {
      textAlign: 'center',
      alignItems: 'center',
    },
    light: {
      '.MuiTouchRipple-child, .MuiTouchRipple-rippleVisible, .MuiTouchRipple-childLeaving, .MuiTouchRipple-ripple': {
        backgroundColor: `${theme.palette.grey[0.5]} !important`,
      },
      '.MuiTouchRipple-childPulsate': {
        // backgroundColor: `${theme.palette.grey[0.5]} !important`,
      },
      '.MuiTouchRipple-ripplePulsate': {
        backgroundColor: `unset !important`,
      },
    },
    outlined: {
      '.MuiTouchRipple-child, .MuiTouchRipple-rippleVisible, .MuiTouchRipple-childLeaving, .MuiTouchRipple-ripple': {
        backgroundColor: `${theme.palette.primary.lighter} !important`,
      },
      '.MuiTouchRipple-childPulsate': {
        backgroundColor: `${theme.palette.primary.light} !important`,
      },
      '.MuiTouchRipple-ripplePulsate': {
        backgroundColor: `unset !important`,
      },
    },
    startIcon: {
      fontSize: '1em',
      height: '0.9em',
      alignItems: 'center',
      mr: '0.6em',
    },
    endIcon: {
      fontSize: '1em',
      height: '0.9em',
      alignItems: 'center',
      ml: '0.6em',
    },
  },
  variants: [
    {
      props: { variant: 'text' },
      style: {
        ...AppStyle.baseClickable(theme),
        px: '0.5em',
        fontWeight: 500,
        letterSpacing: 0,
        textDecoration: 'underline',
      },
    },
    {
      props: { variant: 'contained' },
      style: {
        ...AppStyle.baseOutlinedClickable(theme),
        color: `${Palette.WHITE} !important`,
        border: `0.5px solid ${theme.palette.button.main} !important`,
        backgroundColor: `${theme.palette.button.main} !important`,
        '&:hover': {
          color: `${Palette.DARK_ONYX} !important`,
          border: `0.5px solid ${theme.palette.button.main} !important`,
          backgroundColor: `${Palette.WHITE} !important`,
        },
      } as Interpolation<{theme: Theme}>,
    },
    {
      props: { variant: 'outlined' },
      style: {
        ...AppStyle.outlinedClickable(theme),
      } as Interpolation<{theme: Theme}>,
    },
    {
      props: { variant: 'light' },
      style: {
        ...AppStyle.outlinedClickable(theme),
        color: `${Palette.DARK_ONYX} !important`,
        border: `0.5px solid ${theme.palette.button.main} !important`,
        backgroundColor: `${Palette.WHITE} !important`,
        '&:hover': {
          color: `${Palette.WHITE} !important`,
          border: `0.5px solid ${theme.palette.button.main} !important`,
          backgroundColor: `${theme.palette.button.main} !important`,
        },
      } as Interpolation<{theme: Theme}>,
    },
    {
      props: { size: 'small' },
      style: {
        padding: '0.6em 1em',
      },
    },
    {
      props: { disabled: true },
      style: {
        color: `${theme.palette.grey[4]} !important`,
        backgroundColor: `${theme.palette.background.paper} !important`,
        borderColor: `${theme.palette.grey[0]} !important`,
      },
    },
  ],
})
