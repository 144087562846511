import { Box, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import {
  ResponsiveContainer,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  Tooltip,
} from 'recharts'
import { useDashboardStore } from 'src/feature/dashboard/store/dashboard.store'

export type PositionGapInsightsProps = {
  levelId: string
}

export const PositionGapInsights = observer(({ levelId }: PositionGapInsightsProps) => {
  const dashboardStore = useDashboardStore()

  return (
    <Box sx={styles.root}>
      {dashboardStore.positionInsightsSummary[levelId]?.PositionInsightChartsData.length > 0 && <ResponsiveContainer width='100%' height='100%'>
        <RadarChart cx='50%' cy='50%' outerRadius='100%' data={dashboardStore.positionInsightsSummary[levelId]?.PositionInsightChartsData || []}>
          <PolarGrid />
          <PolarAngleAxis dataKey='skill' tick={false} />
          <PolarRadiusAxis domain={[0, 5]} tickCount={6} tick={false} axisLine={false} />
          {/* Tooltip */}
          <Tooltip
            formatter={(value, name) => [`${value}`, `${name}`]}
            contentStyle={{
              backgroundColor: '#161616',
              borderRadius: '12px',
              padding: '0.5em',
            }}
            itemStyle={{
              fontSize: 12,
              padding: '0.1em 0',
              margin: '0',
            }}
            labelStyle={{
              color: '#fff',
              fontSize: 12,
              fontWeight: 'bold',
              paddingBottom: '0.1em',
            }}
          />
          {/* Expected Ratings */}
          <Radar
            name='Expected'
            dataKey='expectedRating'
            stroke='#27827A'
            fill='#27827A'
            fillOpacity={0.9}
          />
          {/* Average Ratings */}
          <Radar
            name='Average Rating'
            dataKey='averageUserRating'
            stroke='#E6F43B'
            fill='#E6F43B'
            fillOpacity={0.5}
          />
        </RadarChart>
      </ResponsiveContainer>}
      {dashboardStore.positionInsightsSummary[levelId]?.PositionInsightChartsData.length === 0 && <Typography>No data available</Typography>}
    </Box>
  )
})

const styles = {
  root: {
    width: '10em',
    height: '10em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}
