import { axiosInstance } from 'src/util/axios/instance.axios'
import { UnusedUserSkills, UnusedSkillsList } from '../model/unused-skills-model'

export async function getUnusedUserSkills(): Promise<UnusedUserSkills[]> {
  const { data } = await axiosInstance.request<UnusedUserSkills[]>({
    url: `/unused-skills/unused-user-skills/`,
  })

  return data
}

export async function getUnusedSkillsList(skip: number, take: number): Promise<UnusedSkillsList[]> {
  const { data } = await axiosInstance.request<UnusedSkillsList[]>({
    url: `/unused-skills/unused-skills-list/?skip=${skip}&take=${take}`,
  })

  return data
}

export async function addUnusedSkills(skillName: string): Promise<UnusedUserSkills> {
  const { data } = await axiosInstance.request<UnusedUserSkills>({
    url: `/unused-skills/save-unused-skills/`,
    method: 'POST',
    data: { skillName },
  })

  return data
}

export async function deleteUnusedUserSkills(skillId: string): Promise<void> {
  await axiosInstance.request<any>({
    url: `/unused-skills/delete-unused-user-skills/${skillId}`,
    method: 'delete',
  })
}

export async function getUnusedUserRecommendedSkills(skip: number, take: number): Promise<UnusedSkillsList[]> {
  const { data } = await axiosInstance.request<UnusedSkillsList[]>({
    url: `/unused-skills/unused-user-recommended-skills/?skip=${skip}&take=${take}`,
  })

  return data
}

export async function updateUnusedUserSkillLevel(skillId: string, selectedLevel: number): Promise<void> {
  await axiosInstance.request<any>({
    url: `/unused-skills/update-unused-user-skills/${skillId}`,
    method: 'PUT',
    data: { selectedLevel },
  })
}
