import { Box, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Sector,
} from 'recharts'
import { useDashboardStore } from 'src/feature/dashboard/store/dashboard.store'

export type TeamInsightsChartProps = {
  levelId: string
}

const getRandomColor = () => {
  const letters = '0123456789ABCDEF'
  let color = '#'
  
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  
  return color
}

const renderActiveShape = (props: any) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload } = props

  const scaleFactor = 5 // Tune this factor to control the effect
  const adjustedOuterRadius = outerRadius + payload.value / scaleFactor

  return (
    <Sector
      cx={cx}
      cy={cy}
      innerRadius={innerRadius}
      outerRadius={adjustedOuterRadius}
      startAngle={startAngle}
      endAngle={endAngle}
      fill={fill}
    />
  )
}

export const TeamInsightsChart = observer(({ levelId }: TeamInsightsChartProps) => {
  const dashboardStore = useDashboardStore()
  const [chartData, setChartData] = useState<{ id: string, name: string; value: number; color: string }[]>([])

  const allIndices = chartData.map((_, index) => index)

  useEffect(() => {
    const organisationChildLevels = dashboardStore.organisationLevelOverview
      .filter(level => level.parentLevel === levelId)

    const initialMetrics: { id: string, name: string, value: number, color: string }[] = []

    const updatedMetrics = organisationChildLevels.reduce((acc, level) => {
      const summary = dashboardStore.positionInsightsSummary[level.id]

      if (summary) {
        const totalExpectedRating = summary.PositionInsightChartsData.reduce((sum, data) => sum + data.expectedRating, 0)
        const totalAverageRating = summary.PositionInsightChartsData.reduce((sum, data) => sum + data.averageUserRating, 0)

        if (totalExpectedRating > 0 && totalAverageRating > 0) {
          acc.push({ id: level.id, name: level.name, value: totalAverageRating, color: getRandomColor() })
        }
      }

      return acc
    }, initialMetrics)
    setChartData(updatedMetrics)
  }, [levelId])

  return (
    <Box sx={styles.root}>
      {chartData.length > 0 && <ResponsiveContainer width='100%' height='100%'>
        <PieChart>
          <Pie
            data={chartData}
            dataKey='value'
            cx='50%'
            cy='50%'
            innerRadius={40}
            outerRadius={45}
            paddingAngle={1}
            activeIndex={allIndices}
            activeShape={renderActiveShape}
          >
            {chartData.map(entry => (
              <Cell
                key={`cell-${entry.id}`}
                fill={entry.color}
                stroke='none'
              />
            ))}
          </Pie>
          <Tooltip
            formatter={(value, name) => [`${value}`, `${name}`]}
            contentStyle={{
              backgroundColor: '#161616',
              borderRadius: '12px',
              padding: '0.5em',
            }}
            itemStyle={{
              fontSize: 12,
              padding: '0.1em 0',
              margin: '0',
              color: '#E6F43B',
            }}
            labelStyle={{
              color: '#fff',
              fontSize: 12,
              fontWeight: 'bold',
              paddingBottom: '0.1em',
            }}
          />
        </PieChart>
      </ResponsiveContainer>}
      {chartData.length === 0 && <Typography>No data available</Typography>}
    </Box>
  )
})

const styles = {
  root: {
    width: '10em',
    height: '10em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}
