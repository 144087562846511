import React from 'react'
import AccessibilityNewRoundedIcon from '@mui/icons-material/AccessibilityNewRounded'
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded'
import WorkRoundedIcon from '@mui/icons-material/WorkRounded'
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded'
import Diversity3RoundedIcon from '@mui/icons-material/Diversity3Rounded'
import GavelRoundedIcon from '@mui/icons-material/GavelRounded'
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded'
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded'
import HealthAndSafetyRoundedIcon from '@mui/icons-material/HealthAndSafetyRounded'
import InventoryRoundedIcon from '@mui/icons-material/InventoryRounded'
import PublicRoundedIcon from '@mui/icons-material/PublicRounded'
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded'
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded'
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded'
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded'
import CodeRoundedIcon from '@mui/icons-material/CodeRounded'
import FolderCopyRoundedIcon from '@mui/icons-material/FolderCopyRounded'
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded'
import PersonOffRoundedIcon from '@mui/icons-material/PersonOffRounded'
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded'
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded'
import LeaderboardIcon from '@mui/icons-material/Leaderboard'
import SettingsIcon from '@mui/icons-material/Settings'
import SchoolIcon from '@mui/icons-material/School'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import BusinessIcon from '@mui/icons-material/Business'
import DashboardCustomizeRoundedIcon from '@mui/icons-material/DashboardCustomizeRounded'
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded'
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded'
import KeyboardDoubleArrowLeftRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftRounded'
import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded'
import ExtensionRoundedIcon from '@mui/icons-material/ExtensionRounded'
import TuneRoundedIcon from '@mui/icons-material/TuneRounded'
import CameraRoundedIcon from '@mui/icons-material/CameraRounded'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import DriveFileRenameOutlineRoundedIcon from '@mui/icons-material/DriveFileRenameOutlineRounded'

export const iconMapping: { [key: string]: React.ElementType } = {
  AccessibilityNewRoundedIcon,
  SchoolRoundedIcon,
  WorkRoundedIcon,
  CampaignRoundedIcon,
  Diversity3RoundedIcon,
  GavelRoundedIcon,
  TrendingUpRoundedIcon,
  FavoriteRoundedIcon,
  HealthAndSafetyRoundedIcon,
  InventoryRoundedIcon,
  PublicRoundedIcon,
  QuestionAnswerRoundedIcon,
  BusinessRoundedIcon,
  SupportAgentRoundedIcon,
  PeopleRoundedIcon,
  CodeRoundedIcon,
  FolderCopyRoundedIcon,
  HowToRegRoundedIcon,
  PersonOffRoundedIcon,
  PeopleAltRoundedIcon,
  CheckCircleOutlineRoundedIcon,
  HourglassEmptyRoundedIcon,
  AssignmentRoundedIcon,
  LeaderboardIcon,
  SettingsIcon,
  SchoolIcon,
  TrendingUpIcon,
  BusinessIcon,
  DashboardCustomizeRoundedIcon,
  ArrowUpwardRoundedIcon,
  ArrowDownwardRoundedIcon,
  KeyboardDoubleArrowLeftRoundedIcon,
  KeyboardDoubleArrowRightRoundedIcon,
  ExtensionRoundedIcon,
  TuneRoundedIcon,
  CameraRoundedIcon,
  LogoutRoundedIcon,
  DriveFileRenameOutlineRoundedIcon,
}