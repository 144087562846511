import { GenericStoreAsyncMethod } from 'src/util/mobx/generic-store/decorator/async-method.decorator'
import { GenericStore } from 'src/util/mobx/generic-store/generic.store'
import { createStore } from 'src/util/mobx/generic-store/store-creator'
import { downloadReport, getAllSkills, getDepartmentCourses, getMyOverallLearningProgress, getOrganisationLevelOverview, getOverallCourseOverview, getOverallLearningProgress, getPositionInsightsSummary, getSkillGapOverview, getTeamCourseOverview, getTeamOverview, getUserCourses, getUserOverview, getUserStatus } from '../api/dashboard'
import { OrganisationLevelOverview, OverallCourseOverview, OverallLearningProgress, PositionInsightsSummary, SkillSetList, skillGapMetrics, SkillGapOverview, TeamCourseOverview, TeamMetrics, TeamOverview, UserCourses, UserMetrics, UserOverview, UserStatus, UserStatusMetrics, SkillSetMetrics, MyLearningProgress } from '../model/dashboard-model'
import { CourseListItem } from 'src/feature/e-learning/model/ELearningCourseListItem'

export class dashboardStore extends GenericStore {
  teamCourseOverview: TeamCourseOverview | undefined
  teamOverview: TeamOverview = {}
  userOverview: UserOverview = {}
  departmentAssignedCourses: CourseListItem[] = []
  skillGapOverview: SkillGapOverview = {}
  userCourses: UserCourses | undefined
  userStatus: UserStatus = {}
  allSkills: SkillSetList = { count: 0, skillSets: [] }
  positionInsightsSummary: PositionInsightsSummary = {}
  organisationLevelOverview: OrganisationLevelOverview[] = []
  overallLearningProgress: OverallLearningProgress | undefined
  overallCourseOverview: OverallCourseOverview | undefined
  myLearningProgress: MyLearningProgress | undefined

  constructor() {
    super('dashboardStore')

    super.observe(this)
    super.persist({ encrypt: false })
  }

  reset() {
    this.teamCourseOverview = undefined
    this.teamOverview = {}
    this.userOverview = {}
    this.departmentAssignedCourses = []
    this.skillGapOverview = {}
    this.userCourses = undefined
    this.userStatus = {}
    this.allSkills = { count: 0, skillSets: [] }
    this.positionInsightsSummary = {}
    this.organisationLevelOverview = []
    this.overallLearningProgress = undefined
    this.overallCourseOverview = undefined
    this.myLearningProgress = undefined
  }

  @GenericStoreAsyncMethod()
  async getOrganisationLevelOverview() {
    const data = await getOrganisationLevelOverview()
    this.organisationLevelOverview = data
  }

  @GenericStoreAsyncMethod()
  async getOverallLearningProgress(days?: number) {
    const data = await getOverallLearningProgress(days)
    this.overallLearningProgress = data
  }

  @GenericStoreAsyncMethod()
  async getOverallCourseOverview() {
    const data = await getOverallCourseOverview()
    this.overallCourseOverview = data
  }

  @GenericStoreAsyncMethod()
  async getTeamCourseOverview() {
    const data: TeamCourseOverview = await getTeamCourseOverview()
    this.teamCourseOverview = data
  }

  @GenericStoreAsyncMethod()
  async getTeamOverview(levelId: string) {
    const data: TeamMetrics = await getTeamOverview(levelId)
    this.teamOverview[levelId] = data
  }

  @GenericStoreAsyncMethod()
  async getUserOverview(levelId: string, userId: string) {
    const data: UserMetrics = await getUserOverview(levelId, userId)
    this.userOverview[userId] = data
  }

  @GenericStoreAsyncMethod()
  async getDepartmentCourses(levelId: string) {
    const data: CourseListItem[] = await getDepartmentCourses(levelId)
    this.departmentAssignedCourses = data
  }

  @GenericStoreAsyncMethod()
  async getSkillGapOverview(setName: string, setIds: string[]) {
    const data: skillGapMetrics[] = await getSkillGapOverview(setIds)
    this.skillGapOverview[setName] = data
  }

  @GenericStoreAsyncMethod()
  async getUserCourses(userId: string) {
    const data: UserCourses = await getUserCourses(userId)
    this.userCourses = data
  }

  @GenericStoreAsyncMethod()
  async getUserStatus(userId: string) {
    const data: UserStatusMetrics[] = await getUserStatus(userId)
    this.userStatus[userId] = data
  }

  @GenericStoreAsyncMethod()
  async downloadReport(levelId?: string) {
    const reportData = await downloadReport(levelId)
  
    const blob = new Blob([reportData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'report.xlsx'
    document.body.appendChild(a)
    a.click()
    a.remove()
    window.URL.revokeObjectURL(url)
  }

  @GenericStoreAsyncMethod()
  async getAllSkills(skip: number, take: number) {
    const orgLevelIds = this.organisationLevelOverview.map(level => level.id)
    const data = await getAllSkills(orgLevelIds || [], skip, take)
  
    const skillSetMap = new Map<string, SkillSetMetrics>()

    for (const skillSet of data.skillSets) {
      const existing = skillSetMap.get(skillSet.setName)
      
      if (existing) {
        if (!existing.ids) {
          existing.ids = []
        }
        existing.ids.push(skillSet.id)
      } else {
        skillSetMap.set(skillSet.setName, {
          id: skillSet.id,
          setName: skillSet.setName,
          ids: [skillSet.id],
        })
      }
    }

    const mergedSkills = Array.from(skillSetMap.values())

    this.allSkills = { count: data.count, skillSets: mergedSkills }
  }

  @GenericStoreAsyncMethod()
  async getPositionInsightsSummary(levelId: string) {
    const data = await getPositionInsightsSummary(levelId)
    this.positionInsightsSummary[levelId] = data
  }

  @GenericStoreAsyncMethod()
  async getMyLearningProgress(days?: number) {
    const data = await getMyOverallLearningProgress(days)
    this.myLearningProgress = data
  }
}

export const {
  storeInstance: dashboardStoreInstance,
  useStore: useDashboardStore,
} = createStore(new dashboardStore())
