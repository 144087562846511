import { AppBox } from '../../../../component/common/analog/molecule/AppBox'
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useELearningStore } from '../../store/e-learning.store'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { Caption } from 'src/feature/localization/component/atom/Caption'
import { CourseListPresentationItem } from './CourseListPresentationItem'
import { AppCarousel } from 'src/component/common/analog/molecule/carousel/AppCarousel'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Tooltip, Typography } from '@mui/material'

export const RecommendedCourses = observer(() => {
  const eLearningStore = useELearningStore()
  const { translation } = useLocalizationStore()
  const texts = translation.pages.eLearning
  
  useEffect(() => {
    eLearningStore.getRecommendedCourses()
  }, [])

  return (
    <AppBox sx={styles.root}>
      <AppBox sx={styles.titleContainer}>
        <Tooltip title={texts.courseRecommendationInfo()} arrow><InfoOutlinedIcon fontSize='small' sx={{ color: 'secondary.main' }} /></Tooltip>
        <Typography variant='h4'>{texts.recTitle()}</Typography>
      </AppBox>
      {eLearningStore.recommendedCourses && eLearningStore.recommendedCourses.length !== 0 && <AppCarousel showDots={true} showProgressBar={false}>
        {eLearningStore.recommendedCourses.map(c => <CourseListPresentationItem item={c} key={c.id} />)}
      </AppCarousel>}
      {eLearningStore.recommendedCourses && eLearningStore.recommendedCourses.length === 0 && <Caption sx={{ color: 'text.secondary' }} textValue={texts.missingCourse} />}
    </AppBox>
  )
})

const styles = {
  root: {
    flexDirection: 'column',
    overflow: 'hidden',
    padding: '0.75rem 0.75rem 0.4rem 0.75rem',
    margin: '0 1rem',
    gap: 5,
  },
  titleContainer: {
    gap: 10,
    alignItems: 'center',
  },
}
