import { Header } from '../../../feature/header/component/original/organism/Header'
import { ChildrenProps } from '../../../model/React'
import { AppBox } from '../../common/analog/molecule/AppBox'
import { useViewportSizes } from '../../../hook/sizes/useViewportSizes'
import { NodeId } from '../../../util/node-id'
import { CssUnit } from '../../../feature/theme/model/CssUnit'
import { useScrollSizes } from '../../../hook/sizes/useScrollSizes'
import { SxHelperStyle } from '../../../feature/theme/style/sx-helper.style'
import { findPercentageOfNumber, Percentage } from '../../../model/Number'
import { AppContainer } from '../../common/analog/molecule/AppContainer'
import { Box, Theme, useMediaQuery } from '@mui/material'
import { useAuthStore } from 'src/feature/auth/store/auth.store'
import { WithDrawerlayout } from './WithDrawerLayout'
import { useState } from 'react'

const NORMAL_CONTENT_HEIGHT: Percentage = 58
const DEFAULT_DRAWER_WIDTH: Percentage = 250
const CLOSED_DRAWER_WIDTH: Percentage = 70

export function BaseLayout(props: ChildrenProps) {
  const { isAuthorized } = useAuthStore()
  const header = useScrollSizes(NodeId.HEADER)
  const content = useScrollSizes(NodeId.CONTENT)
  const viewport = useViewportSizes()
  const isSmallScreen = useMediaQuery('(max-width:900px)')

  const [isDrawerOpen, setDrawerOpen] = useState(true)
  const dynamicDrawerWidth = isDrawerOpen ? DEFAULT_DRAWER_WIDTH : CLOSED_DRAWER_WIDTH

  const areHeaderAndContentLessThanViewport = (header?.scroll.height + content?.scroll.height) < viewport?.client.height
  const isContentLongerThanViewport = content?.scroll.height > viewport?.scroll.height
  const normalizedContentHeight = `${(findPercentageOfNumber(viewport?.client.height, NORMAL_CONTENT_HEIGHT)) - header?.scroll.height}px`
  const minContentHeight = isContentLongerThanViewport ? 'unset' : areHeaderAndContentLessThanViewport ? normalizedContentHeight : '100vh'
  const scrollMarginTop = header?.scroll.height ? `${header.scroll.height + 10}px` : '5rem'
  
  return (
    <AppBox sx={styles.root}>
      {isAuthorized && <Header />}
      <Box sx={{ display: 'flex', flex: 1 }}>
        {!isSmallScreen && isAuthorized && (
          <WithDrawerlayout
            drawerWidth={dynamicDrawerWidth}
            isOpen={isDrawerOpen}
            onToggle={() => setDrawerOpen(prev => !prev)}
          />
        )}
        <AppContainer
          id={NodeId.CONTENT}
          sx={styles.content(minContentHeight, scrollMarginTop, dynamicDrawerWidth)}
        >
          {props.children}
        </AppContainer>
      </Box>
    </AppBox>
  )
}

const styles = {
  root: {
    ...SxHelperStyle.flexColumn,
    position: 'relative',
    minHeight: '100%',
  },
  content: (minHeight: CssUnit, scrollMarginTop: CssUnit, dynamicDrawerWidth: CssUnit) => ({ breakpoints }: Theme) => ({
    width: `calc(100% - ${dynamicDrawerWidth}px)`,
    transition: 'width 0.3s ease',
    flexGrow: 1,
    minHeight,
    scrollMarginTop,
    [breakpoints.up('xs')]: {
      padding: 0,
    },
    maxWidth: 'unset',
  }),
}
