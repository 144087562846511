import { debounce } from 'lodash'
import { observer } from 'mobx-react-lite'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { AppSearchBar } from 'src/component/common/analog/molecule/AppSearchBar'
import { useELearningStore } from '../../store/e-learning.store'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { Box } from '@mui/material'
import { AppAnimatedText } from 'src/component/common/analog/molecule/AppAnimatedText'
import { useState } from 'react'

export type CategorySearchProps = {
  setSearchTerm: (term: string) => void
}

export const CategorySearch = observer(({ setSearchTerm }: CategorySearchProps) => {
  const eLearningStore = useELearningStore()
  const { translation } = useLocalizationStore()
  
  const { eLearning } = translation.pages
  const pageSize = 30
  const [isFocused, setIsFocused] = useState(false)

  const handleSearch = debounce(async (event: React.ChangeEvent<HTMLInputElement>) => {
    eLearningStore.clearSearch()
    if (event.target.value.length > 1) {
      setSearchTerm(event.target.value)
      await eLearningStore.search(event.target.value, 0, pageSize)
    } else if (event.target.value.length === 0) {
      setSearchTerm(event.target.value)
      await eLearningStore.getAllCourses('all', 0, pageSize)
    }
  }, 750)

  return (
    <Box sx={styles.root}>
      <AppAnimatedText
        text={eLearning.hrefLink({})}
        delay={50}
        sx={{ fontSize: { xs: '1.5rem', md: '2rem' }, fontWeight: 400, textAlign: 'center' }}
      />
      <AppBox sx={{
        ...styles.searchbarContainer,
        ...(isFocused ? { width: '14rem' } : { width: '7rem' }),
      }}>
        <AppSearchBar
          onChange={handleSearch}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      </AppBox>
    </Box>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: { lg: 'row', md: 'row', xs: 'column' },
    justifyContent: { lg: 'space-between', md: 'space-between', xs: 'center' },
    alignItems: 'center',
    width: '100%',
    gap: 30,
  },
  searchbarContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    width: '7rem',
    transition: 'width 0.3s ease-in-out',
  },
}
