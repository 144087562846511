import { Box, Tooltip, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { SkillSetMetrics } from '../model/dashboard-model'
import { useDashboardStore } from '../store/dashboard.store'
import { useEffect } from 'react'
import { Palette } from 'src/feature/theme/model/Palette'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { AppPaper } from 'src/component/common/analog/molecule/AppPaper'

export interface SkillGapCardsProps {
  skillSet: SkillSetMetrics
}

export const SkillGapCards = observer(({ skillSet }: SkillGapCardsProps) => {
  const dashboardStore = useDashboardStore()

  useEffect(() => {
    dashboardStore.getSkillGapOverview(skillSet.setName, skillSet.ids || [])
  }, [])

  return (
    <Box sx={styles.root}>
      <AppBox sx={{ minWidth: { xs: '100%', md: '20%' }, justifyContent: { xs: 'center', md: 'flex-start' }, alignItems: 'center', gap: 7 }}>
        {(skillSet.ids ?? []).length > 1 && <Tooltip title={'Available in multiple departments'} arrow><InfoOutlinedIcon sx={{ color: 'secondary.main' }} /></Tooltip>}
        <Tooltip title={skillSet.setName} arrow><Typography fontSize={12} sx={{ cursor: 'pointer' }} noWrap>{skillSet.setName}</Typography></Tooltip>
      </AppBox>
      <AppPaper sx={styles.boxWrapper}>
        {dashboardStore.skillGapOverview?.[skillSet.setName]?.map(skill => (
          <Tooltip key={skill.userId} title={`${skill.userName}: ${skill.rating}/5`} arrow><AppBox sx={{ ...styles.box, backgroundColor: skill.isRating === 'exceed' ? 'green' : skill.isRating === 'match' ? 'orange' : Palette.DANGER }} /></Tooltip>
        ))}
      </AppPaper>
    </Box>
  )
})

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 10,
  },
  boxWrapper: {
    flexDirection: 'row',
    padding: 5,
    borderRadius: '7px',
    gap: 7,
    width: { xs: '100%', md: '70%' },
    minHeight: 25,
  },
  box: {
    height: 15,
    width: 40,
    borderRadius: '5px',
    cursor: 'pointer',
  },
  pill: {
    marginLeft: '10px',
    borderRadius: '1rem',
    border: '1px solid',
    padding: '0px 12px',
    backgroundColor: 'background.default',
    color: 'text.secondary',
    minWidth: '2.5em',
    textAlign: 'center',
  },
}