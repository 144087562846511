import { FC, useCallback } from 'react'
import { SnackbarKey, useSnackbar } from 'notistack'
import { AppButton } from '../../../../component/common/analog/molecule/button/AppButton'
import { useLocalizationStore } from '../../../localization/store/localization.store'

interface IProps {
  notificationKey?: SnackbarKey;
  btnLabel?: string;
  btnAction?: () => void;
}

// eslint-disable-next-line react/function-component-definition
const NotifierCloseButton: FC<IProps> = ({ notificationKey, btnLabel, btnAction }): JSX.Element => {
  const { closeSnackbar } = useSnackbar()
  const { translation } = useLocalizationStore()
  const _close = useCallback(() => {
    closeSnackbar(notificationKey)
  }, [notificationKey])

  return (
    <AppButton
      sx={{
        fontSize: 12,
        height: 14,
      }}
      variant='light'
      textValue={!btnLabel ? translation.notification.closeButton : btnLabel}
      onClick={!btnAction ? _close : btnAction}
    />
  )
}

export { NotifierCloseButton }
