import { observer } from 'mobx-react-lite'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { AppPaper } from 'src/component/common/analog/molecule/AppPaper'
import { useSkillAssessmentStore } from '../store/skill-assessment.store'
import { addSkillAssessment } from '../api/skill-assessment-api'
import { Palette } from 'src/feature/theme/model/Palette'
import { SkillSet, SkillSetLevel } from '../model/skill-assessment-model'
import { ModalWindow } from 'src/component/common/analog/template/ModalWindow'
import { useEffect, useState } from 'react'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import { AppButton } from 'src/component/common/analog/molecule/button/AppButton'
import StepButton from '@mui/material/StepButton'
import { Typography, useTheme, Zoom } from '@mui/material'
import { AppDivider } from 'src/component/common/analog/molecule/AppDivider'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'

export type SkillAssessmentLevelsProps = {
  isModalClose: () => void
  isModalOpen: boolean
  skillAssessmentSetIndex: number
}

export const SkillAssessmentLevels = observer((props: SkillAssessmentLevelsProps) => {
  const { translation } = useLocalizationStore()
  const skillAssessmentStore = useSkillAssessmentStore()
  const theme = useTheme()

  const translations = translation.pages.myTeam.rolesButton
  const skillAssessmentSet = skillAssessmentStore.skillSets ?? { skills: [] }
  const [activeStep, setActiveStep] = useState<number>(props.skillAssessmentSetIndex)

  useEffect(() => {
    setActiveStep(props.skillAssessmentSetIndex)
  }, [props.skillAssessmentSetIndex])

  const addUserSkillAssessment = async (skillId: string, selectedSetLevel: number) => {
    await addSkillAssessment(skillId, selectedSetLevel)
    skillAssessmentSet.skills[activeStep].selectedSetLevel = selectedSetLevel
  }

  const handleBackStep = () => {
    if (activeStep > 0) {
      setActiveStep(prevActiveStep => prevActiveStep - 1)
    }
  }

  return (
    <ModalWindow
      modalProps={{
        open: props.isModalOpen,
        onClose: () => props.isModalClose(),
        PaperProps: {
          sx: {
            maxWidth: { xs: '90%', md: '80%', lg: '50%' },
            borderRadius: '20px',
          },
        },
      }}
    >
      <Zoom in={props.isModalOpen} timeout={theme.transitions.duration.standard} mountOnEnter unmountOnExit>
        <AppBox sx={styles.mainContainer}>
          <Stepper activeStep={activeStep} alternativeLabel sx={{ width: { xs: '90%', md: '80%', lg: '60%' }, justifyContent: 'center' }}>
            {skillAssessmentSet?.skills.map((skill: SkillSet, index: number) => (
              <Step key={skill.id} completed={skill.selectedSetLevel !== null && skill.selectedSetLevel !== 0}>
                <StepButton
                  onClick={() => setActiveStep(index)}
                  sx={{
                    '& .MuiStepIcon-root': {
                      color: activeStep === index ? `${Palette.CORAL_REEF_GREEN} !important` : '',
                    },
                    '& .Mui-completed': {
                      color: 'alternate.background',
                    },
                  }}
                ></StepButton>
              </Step>
            ))}
          </Stepper>
          <AppBox flexDirection='column' alignItems='center' justifyContent='center' gap={5}>
            <Typography variant='h1' m={0} sx={{ color: 'text.primary' }}>{skillAssessmentSet?.skills[activeStep].setName}</Typography>
            <Typography sx={{ color: 'text.secondary' }}>{skillAssessmentSet?.skills[activeStep].setHint}</Typography>
          </AppBox>
          <AppBox sx={styles.skillsContainer}>
            {skillAssessmentSet?.skills[activeStep]?.levels.map((level: SkillSetLevel, index: number) => (
              <AppPaper
                sx={{
                  ...styles.descriptionContainer,
                  backgroundColor: level?.setLevel === skillAssessmentSet?.skills[activeStep].selectedSetLevel ? 'alternate.background' : '',
                  '&:hover P': {
                    color: `alternate.text`,
                  },
                }}
                key={level?.id}
                onClick={() => addUserSkillAssessment(skillAssessmentSet?.skills[activeStep].id, level?.setLevel)}
              >
                <Typography color= {level?.setLevel !== skillAssessmentSet?.skills[activeStep].selectedSetLevel ? `alternate.background` : `alternate.text`}>{`${index + 1}. ${level?.setLevelDescription}`}</Typography>
              </AppPaper>
            ))}
          </AppBox>
          <AppDivider />
          <AppBox sx={styles.buttonContainer}>
            <AppButton disabled={activeStep === 0} fontSize={12} variant='text' size='small' color='primary' onClick={handleBackStep}>{translations.backButton()}</AppButton>
            {activeStep === skillAssessmentSet.skills.length - 1 ? (
              <AppButton onClick={() => props.isModalClose()} fontSize={12} size='small' variant="contained" color="primary">{translations.finishButton()}</AppButton>
            ) : (
              <AppButton fontSize={12} size='small' variant="contained" color="primary" onClick={() => setActiveStep(prevActiveStep => prevActiveStep + 1)}>{translations.nextButton()}</AppButton>
            )}
          </AppBox>
        </AppBox>
      </Zoom>
    </ModalWindow>
  )
})

const styles = {
  mainContainer: {
    marginTop: '0.5rem',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 20,
  },
  skillsContainer: {
    flexDirection: 'column',
    gap: 10,
    width: '100%',
    maxHeight: '20rem',
    overflowY: 'auto',
  },
  descriptionContainer: {
    borderColor: 'alternate.background',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'alternate.background',
      opacity: 0.8,
    },
    borderRadius: '15px',
    padding: '0.7em',
    transition: 'transform 0.3s ease, background-color 0.3s ease',
  },
  buttonContainer: {
    width: '100%',
    justifyContent: 'flex-end',
    gap: 10,
  },
}
