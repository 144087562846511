import { observer } from 'mobx-react-lite'
// import { GrowthCard } from '../component/GrowthCard'
import { Box } from '@mui/material'
import { DepartmentList } from '../component/DepartmentList'
import { SkillGapList } from '../component/SkillGapList'
import { useUserStore } from 'src/feature/user/store/user.store'
import { useDashboardStore } from '../store/dashboard.store'
import { useEffect, useState } from 'react'
import { TeamInsights } from '../component/TeamInsights/template/TeamInsights'
import { PositionInsights } from '../component/PositionInsights/template/PositionInsights'
import { OrganisationInsights } from '../component/OrganisationInsights/template/OrganisationInsights'

export const AdministratorDasboardPage = observer(() => {
  const userStore = useUserStore()
  const dashboardStore = useDashboardStore()

  const [showList, setShowList] = useState<boolean>(false)

  useEffect(() => {
    dashboardStore.getOrganisationLevelOverview()
      .then(() => setShowList(true))
      .catch(() => setShowList(false))
  }, [])

  return (
    <Box sx={styles.root}>
      {/* {showList && <GrowthCard />} */}
      {showList && <OrganisationInsights />}
      {(userStore.sitemap?.modules ?? []).filter(s => s.key === 'skillGap').length > 0 && showList && <TeamInsights />}
      {(userStore.sitemap?.modules ?? []).filter(s => s.key === 'skillGap').length > 0 && showList && <PositionInsights />}
      {showList && <DepartmentList />}
      {(userStore.sitemap?.modules ?? []).filter(s => s.key === 'skillGap').length > 0 && showList && <SkillGapList />}
    </Box>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4rem',
    padding: '1rem',
  },
}
