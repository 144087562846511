import { AppStack } from '../../../component/common/analog/molecule/list/AppStack'
import { LINK_LABEL_CLASS_NAME, NavListItem } from '../../../component/common/original/molecule/NavListItem'
import { SidebarProps } from '../model/Sidebar'
import { ChildrenProps } from '../../../model/React'
import { AppDivider } from '../../../component/common/analog/molecule/AppDivider'
import { Icon, useTheme } from '@mui/material'
import { iconMapping } from 'src/util/icon-mapping'
import { TranslatableText } from 'src/feature/localization/component/atom/TranslatableText'

export type DesktopSidebarProps = SidebarProps & ChildrenProps

export function DesktopSidebar(props: DesktopSidebarProps) {
  const theme = useTheme()

  return (
    <>
      {props.nav && (
        <AppStack direction='column' gap={theme.spacing(15)}>
          {Object.entries(props.nav!).filter(([_, v]) => !v.props.hidden)
            .map(([k, v]) => {
              if (v.isDivider) {
                return <AppDivider key={k} {...v.props} />
              }

              return (
                <NavListItem
                  key={k}
                  {...v.props}
                  sx={{ display: 'flex', alignItems: 'center', gap: 7 }}
                >
                  <Icon
                    sx={{
                      color: 'text.primary',
                      transition: 'color 0.3s ease',
                      height: 22,
                      width: 22,
                    }}
                    className={LINK_LABEL_CLASS_NAME}
                    component={iconMapping[v.props?.icon ? v.props.icon : 'CameraRoundedIcon']}
                  />
                  <TranslatableText
                    component={'p'}
                    className={LINK_LABEL_CLASS_NAME}
                    textValue={v.label}
                    sx={{ transition: 'color 0.3s ease' }}
                  />
                </NavListItem>
              )
            })}
        </AppStack>
      )}

      {props.children}
    </>
  )
}
