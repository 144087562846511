import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { AppBox } from '../../../component/common/analog/molecule/AppBox'
import { useSkillAssessmentStore } from '../store/skill-assessment.store'
import { AppProgressLine } from 'src/component/common/analog/molecule/loader/AppProgressLine'
import { SkillAssessmentLevels } from './SkillAssessmentLevels'
import { Box, useTheme } from '@mui/material'
import { AppCircularRating } from 'src/component/common/analog/molecule/AppCircularRating'
import { SkillSet } from '../model/skill-assessment-model'
import { AppConfetti } from 'src/component/common/analog/molecule/confetti/AppConfetti'
import { AppSkeleton } from 'src/component/common/analog/molecule/AppSkelton'
  
export const SkillAssessmentMenu = observer(() => {
  const [skillAssessmentSetIndex, setSkillAssessmentSetIndex] = useState<number>(0)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isAllSkillRated, setIsAllSkillRated] = useState<boolean>(false)
  const skillAssessmentStore = useSkillAssessmentStore()
  const { isLoading } = skillAssessmentStore.getGenericFieldsState('getSkillSets')
  const theme = useTheme()

  useEffect(() => {
    skillAssessmentStore.skillSets = undefined
    skillAssessmentStore.getSkillSets()
  }, [])

  const handleRateSkillSetClick = (selectedSkillSetIndex: number) => {
    setSkillAssessmentSetIndex(selectedSkillSetIndex)
    setIsModalOpen(true)
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
    const allSkillsRated: boolean = skillAssessmentStore?.skillSets?.skills?.every(skill => skill.selectedSetLevel !== null) ?? false
    setIsAllSkillRated(allSkillsRated)
  }

  return (
    <Box>
      <AppConfetti show={isAllSkillRated} />
      <AppBox sx={styles.mainContainer}>
        <AppProgressLine isShowing={isLoading || skillAssessmentStore.skillSets?.skills === undefined} />
        <AppBox sx={styles.ratingWrapper}>
          {!isLoading && skillAssessmentStore.skillSets?.skills?.map((skill: SkillSet, index: number) => (
            <AppCircularRating
              size={150}
              key={skill.id}
              indexKey={index}
              userRating={skill.selectedSetLevel}
              label={skill.setName}
              onRateSkillClick={handleRateSkillSetClick}
              viewLabel='UserAssessmentView'
              defaultColor={theme.palette.background.dark}
            />
          ))}
          {isLoading && (
            <AppSkeleton
              count={10}
              rects={[
                { width: 150, height: 150, borderRadius: '50%' },
              ]}
            />
          )}
        </AppBox>
      </AppBox>
      {!isLoading && skillAssessmentStore.skillSets?.skills && <SkillAssessmentLevels skillAssessmentSetIndex={skillAssessmentSetIndex} isModalOpen={isModalOpen} isModalClose={handleModalClose} />}
    </Box>
  )
})

const styles = {
  mainContainer: {
    flexDirection: { xs: 'column', md: 'column', lg: 'row' },
    alignItems: 'center',
    gap: '2rem',
    justifyContent: 'center',
  },
  ratingWrapper: {
    gap: 20,
    flexWrap: 'wrap',
    justifyContent: { xs: 'center', md: 'flex-start' },
  },
}
